import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterContracts'
})
export class FilterContractsPipe implements PipeTransform {

  transform(value: any, arg: any,type: string): any {
    var result = [];
    if(value != undefined){
      for(const company of value){
        if(arg == undefined){
          result.push(company);
        }else if(company[type] == arg){
          result.push(company);
        }else if(company[type].normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
        .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){
          result.push(company);
        }else if(company[type].normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
          .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase())>-1){
            result.push(company);
          }
        };
          return result;
    }
  }

}
