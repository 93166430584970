import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  userPassword: string;
  userEmail: string;
  @Output() userMail;

  showPassword = false;

  constructor(
    private authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit(){
    const email = this.userEmail;
    const password = this.userPassword;
    this.userMail = this.userEmail;
    this.loading = true;
    this.authService.signIn(email, password);
  }

}
