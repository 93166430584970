<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
  </div>
  <div class="image-contaner">
    <img class="logo-img" src="../../../assets/images/core/mLogo.png" >
  </div>
  <div class="header-container">
    <a class="logo">
      <span style="color:#ff8c21">m</span><span>Contract</span>
    </a>
  </div>
</div>
<div class="header-container">




<div (click)="contracts()" *ngIf="notify" style="cursor: pointer;">
  <img
  src="../../../assets/images/core/campana.png"
  class="rounded-circle"
  height="40px"
  alt=""
  loading="lazy"
  
/>
<span style="font-size:x-small;margin-right: 3rem;color: red;">Uno o mas contrato se venceran pronto</span>
</div>
  
      
  <nb-icon icon="log-out-outline" style="cursor: pointer;" (click)="logout()"></nb-icon>
</div>

<!-- <div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
  </div>
  <div class="image-contaner">
    <img class="logo-img" src="../../../assets/images/core/mLogo.png" >
  </div>
</div>
<div class="header-container">
  <nb-user [name]="userAvatar" class="user-action" nbTooltip="{{userAvatar}}" (click)="navigateUserProfile()"
    nbTooltipStatus="info"
    [picture]="userPicture === userEmail ? 'assets/images/avatar/avatarStudent.png' : userPicture" [showTitle]="true"
    onlyPicture></nb-user>
  <nb-icon icon="log-out-outline" style="cursor: pointer;" (click)="logOut()"></nb-icon>
</div> -->
