<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card style="min-height: 100%; background-color: #ffffffc9;">
  <nb-card-body>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <h3><nb-icon icon="settings-2-outline"></nb-icon> Configuración de contratos</h3>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label>Días de anticipación para ser notificado <input nbInput type="number" status="primary" [(ngModel)]="days"></label>
    </div>
  </nb-card-body>
  <nb-card-footer style="align-self: flex-end;">
    <button nbButton ghost style="color: blue;" (click)="save()">Guardar Configuración</button>
  </nb-card-footer>
</nb-card>
