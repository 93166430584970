<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="card">
    <div class="row">
        <div class="col-sm-9 col-md-9 col-lg-9" style="margin-left: 3rem!important;margin-bottom: 3rem!important;">
            <nb-icon icon="folder-outline" style="font-size: 4rem; color: black;font-weight: bold;"></nb-icon><span
                style="font-size: 3rem;color: black;font-weight: bold;">&nbsp;{{folderName}}</span>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2" style="margin-top: 3rem!important;" *ngIf="btnAdd">
            <div class="row">
                <div class="col-sm-12" style="text-align: center;">
                    <nb-icon icon="plus-circle-outline"
                        style="font-size: 2rem; color: rgb(2, 12, 77);font-weight: bold;cursor: pointer;"
                        (click)="addContracts()"></nb-icon>
                </div>
                <div class="col-sm-12" style="text-align: center;">
                    <span style="font-size: 1rem;color: rgb(242, 143, 51);cursor: pointer;"
                        (click)="addContracts()">Agregar Contrato</span>
                </div>
            </div>

        </div>
        <div class="col-sm-11 col-md-11 col-lg-11"
            style="margin-left: 3rem!important;margin-bottom: 3rem!important;margin-right: 0rem!important;">
            <ng2-smart-table [settings]="settings" [source]="contractStatusList" (edit)="onUserRowSelect($event)"
                (delete)="headTableNavigate($event)" (delete1)="headTableNavigate($event)"
                (custom)="onCustomAction($event)"></ng2-smart-table>
        </div>
    </div>
    <div class="row" class="row" style="justify-content: center!important;" *ngIf="!btnAdd">
        <div class="col-sm-9 col-md-9 col-lg-9"
            style="margin-left: 3rem!important;margin-bottom: 3rem!important;margin-right: 0rem!important;">
            <table style="width: 100%!important;">
                <thead>

                </thead>
                <tbody>
                    <tr *ngFor="let contract of contractsAvailable">
                        <td>{{contract.contractName}}</td>
                        <td style="width: 5rem;text-align: center;">
                            <nb-checkbox [(ngModel)]="contract.add"></nb-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row" style="justify-content: center!important;" *ngIf="!btnAdd">
        <div class="col-sm-3" >
            <button nbButton ghost (click)="addContractsNew()">
                <nb-icon icon="save-outline"></nb-icon>Guardar
            </button>
            <button nbButton ghost (click)="cancel()">
                <nb-icon icon="close-square-outline"></nb-icon>Cancelar
            </button>
        </div>
    </div>

    <nb-card-footer style="align-self: flex-end;">
        <button nbButton ghost (click)="closed()">
            <nb-icon icon="arrow-back-outline"></nb-icon>Regresar
        </button>
      </nb-card-footer>
</nb-card>