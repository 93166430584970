import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { UserModel } from './models/userModel';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  isLogged: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {
  }

  ngOnInit() {
    let user: UserModel;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this.authService.authStatusChanged.subscribe(authenticated => {
      this.isLogged = authenticated;
      if (!authenticated) {
        const pathUrl = this.location.path().split('/');
        if (pathUrl[1] !== 'userAutoConfirm') {
          this.router.navigate(['/Login']);
        }
      }
    });
    this.authService.initAuth();
  }
}
