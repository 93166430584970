<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="card">
    <nb-card-header>
        <h1>Carpetas</h1>
    </nb-card-header>
    <nb-card-body>
        <div class="row" style="position: absolute;top:80%;left:85%;">
            <div class="col-sm-12" style="text-align: center;">
                <nb-icon icon="plus-circle-outline"
                    style="font-size: 3.5rem; color: rgb(2, 12, 77);font-weight: bold;cursor: pointer;"
                    (click)="saveNewFolder()"></nb-icon>
            </div>
            <div class="col-sm-12" style="text-align: center;">
                <span style="font-size: 1.5rem;color: rgb(242, 143, 51);cursor: pointer;"
                    (click)="saveNewFolder()">Crear</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-2" *ngFor="let folder of folders">
                <div class="row" style="text-align: right; margin-bottom: -1px!important;">
                    <div class="col-sm-12">
                        <span title="Eliminar Carpeta" (click)="deleteFolder(folder)">
                            <nb-icon icon="trash-2-outline" style="font-weight: lighter;color: rgb(210, 18, 18);cursor: pointer;">
                            </nb-icon>
                          </span>
                    </div>
                    
                </div>
                <div class="row" (click)="headTableNavigate(folder)" style="cursor: pointer;">
                    
                    <div class="col-sm-12" style="text-align: center;">
                        <nb-icon icon="folder-outline" style="font-size: 12rem;opacity: 60%;"></nb-icon>
                    </div>
                    <div class="col-sm-12" style="text-align: center;">
                        <span style="text-align: center;">{{folder.folderName}}</span>
                    </div>
                </div>
                
            </div>
        </div>

    </nb-card-body>
</nb-card>