<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="card">
  <nb-card-header>
    <div class="row">
      <div class="col-sm-10 col-md-10 col-lg-10 inputs">
        <h1> <img src="../../../../assets/images/search.png" style="width: 3rem !important;"> Búsqueda de contratos</h1>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6" >
        <nb-form-field *ngIf="!filter && !folder && !word && !group" >
          <input nbInput placeholder="Ingrese palabra a buscar" [(ngModel)]="text" (keyup.enter)="elasticSearch(text)" fullWidth>
          <button nbSuffix nbButton (click)="elasticSearch(text)" ghost>
            <nb-icon icon="search-outline"></nb-icon>
          </button>
        </nb-form-field>

        <nb-form-field *ngIf="filter">
          <input nbInput placeholder="Ingrese palabra a buscar" [(ngModel)]="text" (keyup.enter)="searchCategory(text)" fullWidth>
          <button nbSuffix nbButton (click)="searchCategory(text)" ghost>
            <nb-icon icon="search-outline"></nb-icon>
          </button>
        </nb-form-field>

        <nb-form-field *ngIf="folder">
          <input nbInput placeholder="Ingrese palabra a buscar" [(ngModel)]="text" (keyup.enter)="searchFolder(text)" fullWidth>
          <button nbSuffix nbButton (click)="searchFolder(text)" ghost>
            <nb-icon icon="search-outline"></nb-icon>
          </button>
        </nb-form-field>

        <nb-form-field *ngIf="word">
          <input nbInput placeholder="Ingrese palabra a buscar" [(ngModel)]="text" (keyup.enter)="search(text)" fullWidth>
          <button nbSuffix nbButton (click)="search(text)" ghost>
            <nb-icon icon="search-outline"></nb-icon>
          </button>
        </nb-form-field>

        <nb-form-field *ngIf="group">
          <input nbInput placeholder="Ingrese palabra a buscar" [(ngModel)]="text" (keyup.enter)="elasticSearchGroup(text)" fullWidth>
          <button nbSuffix nbButton (click)="elasticSearchGroup(text)" ghost>
            <nb-icon icon="search-outline"> </nb-icon>
          </button>
        </nb-form-field>
        
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <nb-checkbox [(ngModel)]="group" (checkedChange)="changeSearche(3)" style="margin-top: 1rem;"><span style="color: rgb(38 38 239)!important;">Buscar en grupo de contratos</span></nb-checkbox>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: -webkit-center !important;">
        <div class="form-group">
          <label for="employeeContractTemporality" class="label">Filtrar por Carpeta:</label>
          <nb-select fullWidth placeholder="Filtrar por:" [(ngModel)]="folderSelected" required maxlength="0"
            (selectedChange)="changeSearche(1)">
            <nb-option [value]="folder.folderId" *ngFor="let folder of folderList">
              {{folder.folderName}}
            </nb-option>
          </nb-select>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: -webkit-center !important;">
        <div class="form-group">
          <label for="employeeContractTemporality" class="label">Filtrar por Campos de tabla:</label>
          <nb-select fullWidth placeholder="Filtrar por:" [(ngModel)]="parameter" required maxlength="0"
            (selectedChange)="changeSearche(0)">
            <nb-option [value]="employeeContractTemporality.value" *ngFor="let employeeContractTemporality of list">
              {{employeeContractTemporality.name}}
            </nb-option>
          </nb-select>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4" style="text-align: -webkit-center !important;">
        <div class="form-group">
          <label for="employeeContractTemporality" class="label">Filtro de palabras:</label>
          <nb-select fullWidth placeholder="Filtrar por:" [(ngModel)]="wordOption" required maxlength="0"
            (selectedChange)="changeSearche(2)">
            <nb-option [value]="filter.value" *ngFor="let filter of filterWordsType">
              {{filter.name}}
            </nb-option>
          </nb-select>
        </div>
      </div>


      <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: -webkit-center !important;" *ngIf="filter || word || folder">
        <button nbButton ghost (click)="clearFilter()">
          <nb-icon icon="close-outline"></nb-icon>Quitar Filtro
        </button>
      </div>

      <!-- <div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 2rem !important;">
        <textarea nbInput fullWidth id="base64"></textarea>
        <ng2-smart-table [settings]="settingsf" [source]="filteredContractList" (edit)="onUserRowSelect($event)"
          (delete)="headTableNavigate($event)"></ng2-smart-table>
      </div> -->

      <div class="col-sm-8">
        <table>
          <thead>
            <tr>
              <th style="font-weight: lighter;">Nombre del contrato</th>
              <th style="text-align: center;"></th>
              <th style="text-align: center;"></th>
              <th style="text-align: center;"></th>
              <th style="text-align: center;"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="5">
                <input nbInput fullWidth placeholder="Ingrese palabra a buscar" [(ngModel)]="filterfile"
                  (keypress)="searchFile(filterfile)">
              </td>
            </tr>
            <tr *ngFor="let contract of fileContracts">
              <td>
                <span>{{contract.contractName}}</span>
              </td>
              <td style="text-align: center;width: 20px;">
                <span title="Buscar dentro de contrato" (click)="onUserRowSelect(contract)">
                  <nb-icon icon="search-outline" style="font-weight: lighter;color: rgb(70, 69, 69);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>
              <td style="text-align: center; width: 20px;">
                <span title="Ver tabla" (click)="headTableNavigate(contract)">
                  <nb-icon icon="edit-2-outline" style="font-weight: lighter;color: rgb(70, 69, 69);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>
              <td style="text-align: center;width: 20px;">
                <span title="Agregar a grupo de contratos" (click)="addContractGorup(contract)">
                  <nb-checkbox [(ngModel)]="contract.contractAddGroup"></nb-checkbox>
                  <!-- <nb-icon icon="checkmark-square-2-outline"
                    style="font-weight: lighter;color: rgb(70, 69, 69);cursor: pointer;" title="Agregar al grupo"></nb-icon> -->
                </span>
              </td>
              <td style="text-align: center;width: 20px;">
                <span title="Eliminar contrato permanentemente" (click)="deleteContract(contract)">
                  <nb-icon icon="trash-2-outline" style="font-weight: lighter;color: rgb(210, 18, 18);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-sm-4">
        <table>
          <thead>
            <tr>
              <th style="font-weight: lighter;">Grupo de contratos</th>

            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let contract of contractsGroup">
              <td>
                <span>{{contract.contractName}}</span>
              </td>
              <td style="text-align: center;width: 20px;">
                <span (click)="onUserRowSelect(contract)">
                  <nb-icon icon="search-outline" style="font-weight: lighter;color: rgb(70, 69, 69);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>
              <td style="text-align: center; width: 20px;">
                <span (click)="headTableNavigate(contract)">
                  <nb-icon icon="edit-2-outline" style="font-weight: lighter;color: rgb(70, 69, 69);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>

              <td style="text-align: center;width: 20px;">
                <span (click)="deleteContractGroup(contract)">
                  <nb-icon icon="trash-2-outline" style="font-weight: lighter;color: rgb(210, 18, 18);cursor: pointer;">
                  </nb-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>