import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-billing',
  templateUrl: './monthly-billing.component.html',
  styleUrls: ['./monthly-billing.component.scss']
})
export class MonthlyBillingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  settings = {
    hideSubHeader: true,
    mode: "external",
    actions: {
      columnTitle: "",
      edit: false,
      delete: true
    },
    edit: {
      editButtonContent: "<i class='nb-edit'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='nb-trash'></i>"
    },
    columns: {
      id: {
        title: 'FECHA DE FACTURA'
      },
      name: {
        title: 'No. Factura'
      },
      email: {
        title: 'Correo de contacto'
      },
      Due: {
        title: 'Deuda($)'
      }
    },
    attr: {
      class: "table table-bordered"
    },
  };

  data = [
    {
      id: "06/06/2021",
      name: "4578481-B",
      username: "Bret",
      email: "Sincere@april.biz",
      Due: "$584"
    },
    {
      id: "01/06/2021",
      name: "5448941-F",
      username: "Nicholas.Stanton",
      email: "Rey.Padberg@rosamond.biz",
      Due: "$954"
    }
  ];

}
