// export const environment = {
//   production: false,
//   UserPoolId: 'us-east-1_1nvOOUZGX',
//   ClientId: '2sq9msdgj9s65p63tskekq8uio',
//   API_URL: 'https://8fyro32ux0.execute-api.us-east-1.amazonaws.com/',
//   API_URLT: 'https://9us3h8xzkf.execute-api.us-east-1.amazonaws.com/dev/',
//   accessKeyId: 'AKIAV42YBFE657LPY7EB',
//   secretAccessKey: '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',
//   region: 'us-east-1',
//   bucketName: 'mcontract-dev-contracts',
//   bucketNameApp: 'mcontract-dev-myapp',
//   regionSES: 'us-east-1',
//   emailSES: 'https://d2qqziwozpjt7y.cloudfront.net/',
//   emailImage: 'https://d2qqziwozpjt7y.cloudfront.net/assets/images/emailLogo.jpg',
//   cloudFrontUrl: 'https://d2qqziwozpjt7y.cloudfront.net/',
//   identityPoolId: 'us-east-1:7ca232e4-76e6-41a1-a1a8-a76ba0a5b1fa',
//   cognitoDomain: 'mcontract-dev.auth.us-east-1.amazoncognito.com',
//   cognitoRedirectSignIn: 'http://localhost:8083/',
//   cognitoRedirectSignOut: 'http://localhost:8083/',
//   cognitoResponseType: 'code',
//   devsite: 'https://d2qqziwozpjt7y.cloudfront.net',
// };


// POC MONTECRISTO
export const environment = {
  production: false,
  UserPoolId: 'us-east-1_UlncgjTMk',
  ClientId: '5r4t83jabpplqu2o9f0f0h5823',
  API_URL: 'https://gzi2f7y815.execute-api.us-east-1.amazonaws.com/',
  API_URLT: 'https://iobd348dx7.execute-api.us-east-1.amazonaws.com/poc/',
  accessKeyId: 'AKIA52OLPGFAVERJKE3A',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'mcontract-poc-contracts',
  bucketNameApp: 'mcontract-poc-myapp',
  regionSES: 'us-east-1',
  emailSES: 'https://d19poa53ph5wla.cloudfront.net/',
  emailImage: 'https://d19poa53ph5wla.cloudfront.net/assets/images/emailLogo.jpg',
  cloudFrontUrl: 'https://d19poa53ph5wla.cloudfront.net/',
  identityPoolId: 'us-east-1:f836ddad-514f-4a54-9305-a35e4a1bd466',
  cognitoDomain: 'mcontract-dev.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'http://localhost:8083/',
  cognitoRedirectSignOut: 'http://localhost:8083/',
  cognitoResponseType: 'code',
  devsite: 'https://d19poa53ph5wla.cloudfront.net',
};
