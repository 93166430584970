import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractService } from 'src/services/contract.service';
import filteredContracts from '../../../../assets/tables/filteredContracts.json';
import { NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbWindowService } from '@nebular/theme';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { FolderService } from 'src/services/folder.service';
import { element } from 'protractor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contract-formats',
  templateUrl: './contract-formats.component.html',
  styleUrls: ['./contract-formats.component.scss']
})
export class ContractFormatsComponent implements OnInit {
  // @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  loading = false;
  settingsf = filteredContracts;
  filteredContractList = new Array();
  list = [];
  folderSelected;
  folderContracts = [];
  fileContracts = [];
  folderList = [];
  filter = false;
  folder = false;
  filterfile = ""
  text;
  text1;
  parametros;
  pdf;
  group = false;
  contractsGroup = [];
  contractsFilter = [];
  parameter;
  word = false;
  wordOption = 0;
  filterWordsType = [{ value: 1, name: "Por Palabra" }, { value: 2, name: "Por Frase" }]

  constructor(
    private contractservice: ContractService,
    private router: Router,
    private pdfextendedservice: NgxExtendedPdfViewerService,
    private windowService: NbWindowService,
    private dialogservice: NbDialogService,
    private toastrService: NbToastrService,
    private contractService: ContractService,
    private folderService: FolderService
  ) { }

  ngOnInit(): void {
    this.contractService.contractStatusGetAll().then(
      (result: any) => {
        const userDB = result.records[0][0];

        this.table(userDB);
        this.getFolders();


      }
    );
  }

  deleteContract(contract) {


    Swal
      .fire({
        title: "¿Está seguro que desea eliminar este contrato?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        confirmButtonColor: '#004F9F',
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#898989",

      })
      .then(resultado => {
       
        if (resultado.value) {
          this.loading= true;
          
          this.contractService.contractDelete(contract.contractUUID).then(result => {
            if (result.statusCode == 200) {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'success';
              this.toastrService.show(status || '', 'Eliminado correctamente', { position, status });
              
          setTimeout(function(){
            window.location.reload();
            },500)
            }else{
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(status || '', 'Ocurrio un error', { position, status });
            }
          })
          this.loading=false;
        }
      });


  }
  changeSearche(option) {
    if (option == 0) {
      this.filter = true;
      this.folder = false;
      this.word = false;
      this.wordOption = 0;
      this.group = false;
      this.folderSelected = 0;
      this.filteredContractList = new Array();
      if (this.text != "" && this.text != undefined && this.text != null) {
        this.searchCategory(this.text);

      }

    } else if (option == 2) {
      this.word = true;
      this.filter = false;
      this.folder = false;
      this.group = false;
      this.parameter = 0;
      this.folderSelected = 0;
      this.filteredContractList = new Array();
      if (this.text != "" && this.text != undefined && this.text != null) {
        if (this.wordOption == 1) {
          this.elasticSearch(this.text);
        } else {

        }
      }


    } else if (option == 3) {
      this.word = false;
      this.filter = false;
      this.folder = false;
      this.parameter = 0;
      this.folderSelected = 0;
      this.wordOption = 0;
      this.filteredContractList = new Array();
      if (this.text != "" && this.text != undefined && this.text != null) {

        this.elasticSearchGroup(this.text);

      }


    } else {
      this.folder = true;
      this.filter = false;
      this.word = false;
      this.wordOption = 0;
      this.parameter = 0;
      this.group = false;
      this.folderContracts = [];
      this.filteredContractList = new Array();
      this.folderService.folderGetContracts(this.folderSelected).then(response => {
        if (typeof (response) === 'string') {
          response = JSON.parse(response);
        } else {
          throw response.errorMessage;
        }

        this.folderContracts = response.records;
        if (this.text != "" && this.text != undefined && this.text != null) {
          this.searchFolder(this.text);
        }
        this.loading = false;

      })


    }



  }
  clearFilter() {
    this.filter = false;
    this.folder = false;
    this.word = false;
    this.parameter = "";
    this.filteredContractList = new Array();
    if (this.text != "" && this.text != undefined && this.text != null) {
      this.elasticSearch(this.text);
    }

  }

  async table(obj) {
    let a = true;
    await obj.forEach(async (element) => {
      this.contractService.contractFinish(element.contractUUID).then(
        (result: any) => {
          var userDB = result.index_card

          if (userDB.original_key_map != undefined) {
            if (a) {
              const keys = Object.keys(userDB.original_key_map);
              keys.forEach(category => {
                const object = { name: userDB.original_key_map[category], value: category }
                this.list.push(object);
              });

              a = false;
            }

            let contracts = { backend: element, elastic: userDB }
            this.contractsFilter.push(contracts);
          }


        }
      );
    })


  }

  async getFolders() {
    this.folderService.folderGetAll().then(result => {

      this.folderList.push(result.records[0][0]);

      this.folderList = this.folderList[0]

    })
  }
  addContractGorup(element) {

    if (!element.contractAddGroup) {
      this.contractsGroup.push(element);
    } else {
      this.deleteContractGroup(element)
    }
  }
  deleteContractGroup(element) {
    this.contractsGroup = this.contractsGroup.filter(x => x.contractUUID != element.contractUUID);
    this.fileContracts.forEach(x => {
      if (x.contractUUID == element.contractUUID) {
        x.contractAddGroup = false;
      }
    })
  }
  searchCategory(arg) {
    let word = "";
    let option = "";
    let value = this.contractsFilter;
    let type = this.parameter;
    let dataR = new Array<any>();
    if (value != undefined) {
      for (const company1 of value) {
        const company = company1.elastic;
        var result = [];

        if (company[type] == null || company[type] == undefined) {

        } else {
          if (arg == undefined) {
            result.push(company);
          } else if (company[type] == arg) {
            result.push(company);
          } else if (company[type].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1) {
            result.push(company);
          } else if (company[type].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1) {
            result.push(company);
          }
        }



        if (result.length > 0) {

          this.contractservice.contractGetOneById(company1.backend.contractUUID).then(
            (response: any) => {
              var data = JSON.parse(response).records;
              data.forEach(element => {
                dataR.push(element);
              });
              this.filteredContractList = JSON.parse(JSON.stringify(dataR));
              this.filteredContractList.forEach(e => {
                let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
                if (findContract != null && findContract != undefined) {
                  e.contractAddGroup = true;
                } else {
                  e.contractAddGroup = false;
                }

              })
              this.fileContracts = this.filteredContractList;

              this.loading = false;
            },
            (error: any) => {

            }
          )
        }


      };

    }

  }

  searchFile(arg) {

    var result = [];
    this.filteredContractList.forEach(element => {
      if (arg == undefined || arg == "" || arg == null) {
        result.push(element);
      } else if (element.contractName == arg) {
        result.push(element);
      } else if (element.contractName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1) {
        result.push(element);
      } else if (element.contractName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1) {
        result.push(element);
      }
    })
    this.fileContracts = result;


  }



  searchFolder(text) {
    this.loading = true;
    let dataR = new Array<any>();
    this.contractservice.elasticSearch(text).then(
      (response: any) => {

        if (response.hits.total.value > 0) {
          const data = response.hits.hits;
          data.forEach(contract => {
            const id = contract._id;
            this.contractservice.contractGetOneById(id).then(
              (response: any) => {
                var data = JSON.parse(response).records;
                data.forEach(element => {
                  let find = this.folderContracts.find(x => x.contractUUID == element.contractUUID)
                  if (find != undefined && find != null) {
                    dataR.push(element);
                  }


                });
                this.filteredContractList = JSON.parse(JSON.stringify(dataR));
                this.filteredContractList.forEach(e => {
                  let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
                  if (findContract != null && findContract != undefined) {
                    e.contractAddGroup = true;
                  } else {
                    e.contractAddGroup = false;
                  }

                })
                this.fileContracts = this.filteredContractList;
                this.loading = false;
              },
              (error: any) => {

              }
            )
          });
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || '', 'No se encontraron coincidencias', { position, status });
          this.filteredContractList = JSON.parse(JSON.stringify(dataR));
          this.filteredContractList.forEach(e => {
            let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
            if (findContract != null && findContract != undefined) {
              e.contractAddGroup = true;
            } else {
              e.contractAddGroup = false;
            }

          })
          this.fileContracts = this.filteredContractList;
          this.loading = false;
        }
      },
      (error: any) => {
      }
    )
  }

  search(text) {
    if (this.wordOption == 2) {
      this.elasticSearchByPhrase(text)
    } else {
      this.elasticSearch(text);
    }
  }

  elasticSearch(text) {
    this.loading = true;
    let dataR = new Array<any>();
    this.contractservice.elasticSearch(text).then(
      (response: any) => {

        if (response.hits.total.value > 0) {
          const data = response.hits.hits;
          data.forEach(contract => {
            const id = contract._id;
            this.contractservice.contractGetOneById(id).then(
              (response: any) => {
                var data = JSON.parse(response).records;
                data.forEach(element => {
                  dataR.push(element);
                });
                this.filteredContractList = JSON.parse(JSON.stringify(dataR));

                this.filteredContractList.forEach(e => {
                  let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
                  if (findContract != null && findContract != undefined) {
                    e.contractAddGroup = true;
                  } else {
                    e.contractAddGroup = false;
                  }

                })
                this.fileContracts = this.filteredContractList;
                this.loading = false;
              },
              (error: any) => {

              }
            )
          });
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || '', 'No se encontraron coincidencias', { position, status });
          this.filteredContractList = JSON.parse(JSON.stringify(dataR));
          this.filteredContractList.forEach(e => {
            let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
            if (findContract != null && findContract != undefined) {
              e.contractAddGroup = true;
            } else {
              e.contractAddGroup = false;
            }

          })
          this.fileContracts = this.filteredContractList;
          this.loading = false;
        }
      },
      (error: any) => {
      }
    )
  }

  elasticSearchGroup(text) {
    this.loading = true;
    let dataR = new Array<any>();
    this.contractservice.elasticSearch(text).then(
      (response: any) => {
        if (response.hits.total.value > 0) {
          const data = response.hits.hits;
          data.forEach(contract => {
            const id = contract._id;
            this.contractservice.contractGetOneById(id).then(
              (response: any) => {
                var data = JSON.parse(response).records;
                data.forEach(element => {
                  dataR.push(element);
                });
                let listContracts = [];
                this.filteredContractList = JSON.parse(JSON.stringify(dataR));
                this.filteredContractList.forEach(e => {
                  let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
                  if (findContract != null && findContract != undefined) {
                    e.contractAddGroup = true;
                    listContracts.push(e);
                  } else {
                    e.contractAddGroup = false;
                  }

                })
                this.filteredContractList = listContracts;
                this.fileContracts = this.filteredContractList;
                this.loading = false;
              },
              (error: any) => {

              }
            )
          });
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || '', 'No se encontraron coincidencias', { position, status });
          this.filteredContractList = JSON.parse(JSON.stringify(dataR));
          this.filteredContractList.forEach(e => {
            let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
            if (findContract != null && findContract != undefined) {
              e.contractAddGroup = true;
            } else {
              e.contractAddGroup = false;
            }

          })
          this.fileContracts = this.filteredContractList;
          this.loading = false;
        }
      },
      (error: any) => {
      }
    )
  }

  elasticSearchByPhrase(text) {
    this.loading = true;
    let dataR = new Array<any>();
    this.contractservice.elasticSearchByPhrase(text).then(
      (response: any) => {
        if (response.hits.total.value > 0) {
          const data = response.hits.hits;
          data.forEach(contract => {
            const id = contract._id;
            this.contractservice.contractGetOneById(id).then(
              (response: any) => {
                var data = JSON.parse(response).records;
                data.forEach(element => {
                  dataR.push(element);
                });
                this.filteredContractList = JSON.parse(JSON.stringify(dataR));
                this.filteredContractList.forEach(e => {
                  let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
                  if (findContract != null && findContract != undefined) {
                    e.contractAddGroup = true;
                  } else {
                    e.contractAddGroup = false;
                  }

                })
                this.fileContracts = this.filteredContractList;
                this.loading = false;
              },
              (error: any) => {

              }
            )
          });
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || '', 'No se encontraron coincidencias', { position, status });
          this.filteredContractList = JSON.parse(JSON.stringify(dataR));
          this.filteredContractList.forEach(e => {
            let findContract = this.contractsGroup.find(x => x.contractUUID == e.contractUUID)
            if (findContract != null && findContract != undefined) {
              e.contractAddGroup = true;
            } else {
              e.contractAddGroup = false;
            }

          })
          this.fileContracts = this.filteredContractList;
          this.loading = false;
        }
      },
      (error: any) => {
      }
    )
  }

  headTableNavigate(contractUUID) {

    var contract = contractUUID.contractUUID;
    this.router.navigate(['/headContractTable/' + contract]);
  }

  onUserRowSelect(event) {
    const UUID = event.contractPDFS3;
    const title = event.contractName;
    this.windowService.open(
      PdfViewerComponent,
      { title: title, hasBackdrop: true, closeOnEsc: false, windowClass: "window", context: { pdf: UUID, searchString: this.text } },
    );
  }

}
