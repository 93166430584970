import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-units-to-lease',
  templateUrl: './units-to-lease.component.html',
  styleUrls: ['./units-to-lease.component.scss']
})
export class UnitsToLeaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  settings = {
    hideSubHeader: true,
    mode: "external",
    actions: {
      columnTitle: "",
      edit: false,
      delete: true
    },
    edit: {
      editButtonContent: "<i class='nb-edit'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='nb-trash'></i>"
    },
    columns: {
      id: {
        title: 'No. Unidad'
      },
      name: {
        title: 'Localización'
      },
      username: {
        title: 'Area'
      }
    },
    attr: {
      class: "table table-bordered"
    },
  };

  data = [
    {
      id: "1A",
      name: "Guatemala",
      username: "80mts^2",
      email: "Sincere@april.biz"
    },
    {
      id: "2A",
      name: "San Marcos",
      username: "53mts^2",
      email: "Rey.Padberg@rosamond.biz"
    }
  ];

}
