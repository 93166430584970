import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from 'src/services/contract.service';
import contractOneView from '../../../../assets/tables/contractOneView.json';

@Component({
  selector: 'app-contract-one-view',
  templateUrl: './contract-one-view.component.html',
  styleUrls: ['./contract-one-view.component.scss']
})
export class ContractOneViewComponent implements OnInit {
  settings = contractOneView;
  contractOneList = new Array();

  constructor(
    private route: ActivatedRoute,
    private contractservice: ContractService,
  ) { }

  ngOnInit(){
    this.route.paramMap.subscribe((params: any) => {
      this.search(params.params.contractUUID);
    });
  }

  search(event){
    const UUID = event;
    this.contractservice.contractFinish(UUID).then(
      (response: any) => {
        
        const data = response.result.entities;
        this.contractOneList = data;
      },
      error => {
      }
    )
}

}
