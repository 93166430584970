import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import menu from '../../../assets/menus/menu.json';
import contractManagerMenu from '../../../assets/menus/contractManagerMenu.json';
import contractFinderMenu from '../../../assets/menus/contractFinderMenu.json';
import { UserModel } from 'src/app/models/userModel.js';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  role;
  menu: NbMenuItem[];

  constructor(
  ) {
    let user = {} as UserModel;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    // this.role = user.userRole ? user.userRole : 'user';
    this.role = user.userRole;
    switch (this.role) {
      // case 'Administrator':
      //   this.menu = sidebarMenuAdministrator;
      //   break;
      case 'contract_finder':
        this.menu = contractFinderMenu;
        break;
      case 'contract_manager':
        this.menu = contractManagerMenu;
        break;
      default:
        this.menu = menu;
        break;
    }
   }

  ngOnInit() {
  }

}
