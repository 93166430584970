import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '../app/models/userModel';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) {}

  saveUserOnTable(user: UserModel): Promise<any> {
    let params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URLT + 'mcontract-user', params, { headers}).toPromise();
  }

  getAllUsers(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URLT + 'mcontract-user', { headers}).toPromise();
  }

  oneUserBackend(userEmail){
    const filter = userEmail;
    return this.http.get(environment.API_URLT + 'mcontract-userNoLog' + '/one/' + filter).toPromise();
  }

  oneUser(userID){
    const filter = userID;
    return this.http.get(environment.API_URLT + 'mcontract-user' + '/one/' + filter).toPromise();
  }

}
