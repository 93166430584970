import { Component, OnInit } from '@angular/core';
import { ContractService } from 'src/services/contract.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
@Component({
  selector: 'app-contract-settings',
  templateUrl: './contract-settings.component.html',
  styleUrls: ['./contract-settings.component.scss']
})
export class ContractSettingsComponent implements OnInit {
   days;
   loading=false;
  constructor(private contractService: ContractService,
    private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.loading = true;
    this.contractService.contractStatusGetAll().then(
      (result: any) => {
        const userDB=result.records[0][0];
        userDB.forEach(element => {
          
          
          if (element.contractDaysEnd == null) {
            
          }else{
            this.days=element.contractDaysEnd
          }
          
           
        });
        this.loading = false;
        
      }
    );
  }


  save(){
    this.loading = true;
    this.contractService.contractUpdateDays(this.days).then(result => {
    
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || '', 'Actualizado correctamente', { position, status });
          this.loading=false;
          setTimeout(function(){
            window.location.reload();
            },500)

    })
    // this.contractService.contractStatusGetAll().then(
    //   (result: any) => {
    //     const userDB=result.records[0][0];
    //     userDB.forEach(element => {
    //       console.log(element);

    //       this.contractService.contractUpdateStatus(element.contractUUID,element.contractStatus,this.days).then(
    //       (response: any) => {
    //         console.log(response);
    //       },
    //       error => {
    //         console.log(error)
    //       }
    //     )

    //     });
    //     setTimeout(function(){
    //     window.location.reload();
    //     },4600)
        
        
    //   }
    // );
  }

}
