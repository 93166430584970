import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  settings = {
    hideSubHeader: true,
    mode: "external",
    actions: {
      columnTitle: "",
      edit: false,
      delete: true
    },
    edit: {
      editButtonContent: "<i class='nb-edit'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='nb-trash'></i>"
    },
    columns: {
      no: {
        title: 'No. Servicio'
      },
      id: {
        title: 'Servicio'
      }
    },
    attr: {
      class: "table table-bordered"
    },
  };

  data = [
    {
      no: "1",
      id: "Agua potable",
    },
    {
      no: "2",
      id: "Internet",
    },
    {
      no: "3",
      id: "Mantenimiento",
    },
    {
      no: "4",
      id: "Basura",
    }
  ];

}
