import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  settings = {
    hideSubHeader: true,
    mode: "external",
    actions: {
      columnTitle: "",
      edit: false,
      delete: true
    },
    edit: {
      editButtonContent: "<i class='nb-edit'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='nb-trash'></i>"
    },
    columns: {
      id: {
        title: 'ClienteID'
      },
      name: {
        title: 'Nombre de cliente'
      },
      username: {
        title: 'Nombre de usuario'
      },
      email: {
        title: 'Email'
      }
    },
    attr: {
      class: "table table-bordered"
    },
  };

  data = [
    {
      id: 1,
      name: "Leanne Graham",
      username: "Bret",
      email: "Sincere@april.biz"
    },
    {
      id: 11,
      name: "Nicholas DuBuque",
      username: "Nicholas.Stanton",
      email: "Rey.Padberg@rosamond.biz"
    }
  ];

}
