import { Component, OnInit } from '@angular/core';
import {
  NbSidebarService,
  NbThemeService,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
  NbComponentStatus,
  NbToastrService
} from '@nebular/theme';
import { AuthService } from 'src/services/auth.service';
import { LayoutService } from '../utils/layout.service';
import { Router } from '@angular/router';
import { ContractService } from 'src/services/contract.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loading = false;
  list=[];
  data=[];
  notify = false;
  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
    private contractService: ContractService,
  ) { }

  async ngOnInit() {
    this.contractService.contractStatusGetAll().then(
     (result: any) => {
       const userDB=result.records[0][0];
       this.table(userDB);
       
     }
   );
   
 }
 async table(obj){
  let ob = [];
      await obj.forEach(async(element) => {
        
          this.contractService.contractFinish(element.contractUUID).then(
           (result: any) => {
             var userDB = result.index_card
             
             if (userDB.vencimientoplazodelarrendamiento != null && userDB.vencimientoplazodelarrendamiento != undefined ) {
               
               
               var currentDate = new Date();
               let currentDate1 = currentDate.getFullYear() + "-" + (currentDate.getMonth()) + "-" + currentDate.getDate();
               // const formatDate = (currentDate)=>{
               //   let formatted_date = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear()
               //    return formatted_date;
               //   }
                 
               var fechaInicio = new Date(currentDate1).getTime();
               var fechaFin    = new Date(userDB.vencimientoplazodelarrendamiento).getTime();
               var fechaFin1 =  new Date(userDB.vencimientoplazodelarrendamiento)
               element.dateFinish = fechaFin1.getFullYear() + "-" + (fechaFin1.getMonth()) + "-" + fechaFin1.getDate();
              
              ob.push(element);
              this.contractService.contractUpdateDateFinish(element.contractUUID,element.dateFinish).then(resp=>{})
               var diff = fechaFin - fechaInicio;
               var dayf = (diff/(1000*60*60*24)).toFixed(0);
               if (parseInt(dayf) > -7) {
                if (element.contractDaysEnd >= parseInt(dayf)) {
                  this.notify = true;
                 } 
               }
               
               
                this.list.push(result.index_card);
                this.data.push({
                 id: userDB.vencimientoplazodelarrendamiento,
                 name: userDB.empresaarrendante,
                 contact: (diff/(1000*60*60*24)).toFixed(0),
                 email: userDB.clientearrendataria
               })
               
             }
             
           }
         );
         
       })
    
     
     

 }

 getTable(params){
   this.contractService.contractFinish(params).then(
     (result: any) => {
       this.list.push(result.index_card);
       return result.index_card;
     }
   );
 }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  logout(){
    this.authService.logout();
  }

  contracts(){
    this.router.navigate(['/VencimientoDeContratos']);
  }

}
