import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { NbDialogRef, NbWindowRef, NbWindowService, NB_WINDOW_CONTEXT } from '@nebular/theme';
import { FindResultMatchesCount, FindState, NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit{
  loading = false;
  public highlightAll = true;
  public matchCase = false;
  public wholeWord = true;
  public ignoreAccents = false;
  public findMultipleSearchTexts = true;
  public zoomSetting = 100
  findState;
  currentMatchNumber: number;
  totalMatches: number;
  public _searchtext = '';

  @Input() pdf: any;
  @Input() searchString: any;

  constructor(private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService) {
  }

  ngOnInit(){
    this.loading = true;
    var that = this;
    setTimeout(function(){
    that.search();
    that.loading = false;
    },2000);
  }

  search(){
    this.ngxExtendedPdfViewerService.find(
      this.searchString,
      { highlightAll: this.highlightAll,
        matchCase: this.matchCase,
        wholeWords: this.wholeWord,
        findMultipleSearchTexts: this.findMultipleSearchTexts,
        ignoreAccents: this.ignoreAccents});
  }



  // public get searchtext(): string {
  //   return this.searchString;
  // }

  // public set searchtext(text: string) {
  //    if (this.ngxExtendedPdfViewerService.find(
  //             text,
  //             { highlightAll: this.highlightAll,
  //               matchCase: this.matchCase,
  //               wholeWords: this.wholeWord,
  //               ignoreAccents: this.ignoreAccents})) {
  //     this.searchString = text;
  //   }
  // }

  public onCheckboxClicked() {
    this.ngxExtendedPdfViewerService.find(this.searchString);
  }

  public findNext(): void {
    this.ngxExtendedPdfViewerService.findNext();
  }

  public findPrevious(): void {
    this.ngxExtendedPdfViewerService.findPrevious();
  }

  public updateFindState(result: FindState) {
    this.findState = result;
  }

  public updateFindMatchesCount(result: FindResultMatchesCount) {
    this.currentMatchNumber = result.current;
    this.totalMatches = result.total;
  }



}
