import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/userModel';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';
import userRole from '../../../../assets/data/userRole/userRole.json';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss']
})
export class UserCreationComponent implements OnInit {
  localUser;
  loading = false;
  checked = false;
  user = {} as UserModel;
  userRoleList = userRole;

  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  ngOnInit(){
    let user = {} as UserModel;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
      this.localUser = user;
    }
  }

  generatePassword() {
    // No cambiar los var loquea el generador.
    const length = 5;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const punctuation = '!@#$%&';
    let retVal = '';
    for (var i = 0, n = lowercase.length; i < length; ++i) {
      retVal += lowercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = uppercase.length; i < length; ++i) {
        retVal += uppercase.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = numbers.length; i < length; ++i) {
          retVal += numbers.charAt(Math.floor(Math.random() * n));
          // tslint:disable-next-line:no-shadowed-variable
          for (var i = 0, n = punctuation.length; i < length; ++i) {
            retVal += punctuation.charAt(Math.floor(Math.random() * n));
          }
        }
      }
    }
    return retVal;
  }

  async saveUser() {
    Swal.fire({
      title: 'Crear usuario',
      text: "¿Esta seguro de crear el usuario?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Crear'
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.user.userPassword = this.generatePassword();
        if (this.user.userPassword.length >= 8){
          const UserSub: any = await this.authService.signUp(this.user);
          this.user.userCognitoCode = UserSub.UserSub;
          this.user.userStatus = "1";
          this.user.userCreationUser = this.localUser.email;
          this.userService.saveUserOnTable(this.user).then(
            response => {
              if (response.result === true) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show(status || 'Success', 'El usuario a sido creado', { position, status });
                this.loading = false;
                Swal.fire(
                  'Creado!',
                  'Usted acaba de crear el siguiente usuario' + ' ' +this.user.userEmail,
                  'success'
                ).then(
                  result=> {
                    if (result.isConfirmed) {
                      window.location.reload();
                     }
                  }
                )
              } else {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'warning';
                this.toastrService.show(status || 'warning', response.message, { position, status });
              }
            },
            error => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(status || 'Danger', <any>error, { position, status });
            }
          )
        } else {
        }
      }
    })
    // this.loading = true;
    // this.user.userPassword = this.generatePassword();
    // if (this.user.userPassword.length >= 8){
    //   const UserSub: any = await this.authService.signUp(this.user);
    //   this.user.userCognitoCode = UserSub.UserSub;
    //   this.user.userStatus = "1";
    //   this.user.userCreationUser = this.localUser.email;
    //   this.userService.saveUserOnTable(this.user).then(
    //     response => {
    //       if (response.result === true) {
    //         const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //         const status: NbComponentStatus = 'success';
    //         this.toastrService.show(status || 'Success', 'El usuario a sido creado', { position, status });
    //         this.loading = false;
    //         window.location.reload();
    //       } else {
    //         const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //         const status: NbComponentStatus = 'warning';
    //         this.toastrService.show(status || 'warning', response.message, { position, status });
    //       }
    //     },
    //     error => {
    //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //       const status: NbComponentStatus = 'danger';
    //       this.toastrService.show(status || 'Danger', <any>error, { position, status });
    //     }
    //   )
    // } else {
    // }
  }

  toggle(checked: boolean) {
    this.checked = checked;
  }

}
