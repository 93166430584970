<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="meetCard">
  <nb-card-body>
    <ngx-extended-pdf-viewer [handTool]="false"
    [showHandToolButton]="false" [(src)]="pdf" useBrowserLocale="false"
    [minifiedJSLibraries]="false"
    [(zoom)]="zoomSetting"
    [showRotateButton]="false"
    [showBookmarkButton]="false"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="false"
    [showSecondaryToolbarButton]="false"
    (updateFindMatchesCount)="updateFindMatchesCount($event)"
    (updateFindState)="updateFindState($event)"></ngx-extended-pdf-viewer>
  </nb-card-body>
</nb-card>
