<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card>
  <nb-card-body>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6">
          <h3>Editar tabla de contrato</h3>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: end;">
          <button nbButton ghost ><nb-icon icon="cloud-download-outline"></nb-icon> Exportar </button>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Empresa / Arrendante</label>
          <input nbInput fullWidth [(ngModel)]="headTable.empresaarrendante">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Parque</label>
          <input nbInput fullWidth [(ngModel)]="headTable.parque">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Número de finca</label>
          <input nbInput fullWidth [(ngModel)]="headTable.numerodefinca">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Localización</label>
          <input nbInput fullWidth [(ngModel)]="headTable.localizacion">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Fiduciario</label>
          <input nbInput fullWidth [(ngModel)]="headTable.fiduciario">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>M2 arrendados / Area arrendada</label>
          <input nbInput fullWidth [(ngModel)]="headTable.m2arrendadosareaarrendada">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Costo por metro cuadrado / Renta</label>
          <input nbInput fullWidth [(ngModel)]="headTable.costopormetrocuadradorenta">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Moneda</label>
          <input nbInput fullWidth [(ngModel)]="headTable.moneda">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>NIS - Agua</label>
          <input nbInput fullWidth [(ngModel)]="headTable.nisagua">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>NIS - Electricidad</label>
          <input nbInput fullWidth [(ngModel)]="headTable.niselectricidad">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Cliente / Arrendataria</label>
          <input nbInput fullWidth [(ngModel)]="headTable.clientearrendataria">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Cédula jurídica</label>
          <input nbInput fullWidth [(ngModel)]="headTable.cedulajuridica" >
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Contacto CxC</label>
          <input nbInput fullWidth [(ngModel)]="headTable.contactocxc">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Contacto Operación / Notificaciones</label>
          <input nbInput fullWidth [(ngModel)]="headTable.contactooperacionnotificaciones">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Fecha de contrato</label>
          <input nbInput fullWidth [(ngModel)]="headTable.fechadecontrato">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Fecha de entrega</label>
          <input nbInput fullWidth [(ngModel)]="headTable.fechadeentrega">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Fecha inicio de cobro / Fecha inicio de renta</label>
          <input nbInput fullWidth [(ngModel)]="headTable.fechainiciodecobrofechainicioderenta">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Periodo de gracia</label>
          <input nbInput fullWidth [(ngModel)]="headTable.periododegracia">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Mes de aumento</label>
          <input nbInput fullWidth [(ngModel)]="headTable.mesdeaumento">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Aumento anual</label>
          <input nbInput fullWidth [(ngModel)]="headTable.aumentoanual">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Vencimiento / Plazo del Arrendamiento</label>
          <input nbInput fullWidth [(ngModel)]="headTable.vencimientoplazodelarrendamiento">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Plazo</label>
          <input nbInput fullWidth [(ngModel)]="headTable.plazo">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Depósito de garantía</label>
          <input nbInput fullWidth [(ngModel)]="headTable.depositodegarantia">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Monto de alquiler / Renta mensual</label>
          <input nbInput fullWidth [(ngModel)]="headTable.montodealquilerrentamensual">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Cuota de Mantenimiento / CAM</label>
          <input nbInput fullWidth [(ngModel)]="headTable.cuotademantenimientocam">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Equipment Maintence Fee</label>
          <input nbInput fullWidth [(ngModel)]="headTable.equipmentmaintencefee">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Additional Rent</label>
          <input nbInput fullWidth [(ngModel)]="headTable.additionalrent">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Brokers Commissions</label>
          <input nbInput fullWidth [(ngModel)]="headTable.brokerscommissions">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>HVAC</label>
          <input nbInput fullWidth [(ngModel)]="headTable.hvac">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Parqueos /Estacionamientos</label>
          <input nbInput fullWidth [(ngModel)]="headTable.parqueosiestacionamientos">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Monto de Oficinas</label>
          <input nbInput fullWidth [(ngModel)]="headTable.montodeoficinas">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>TIA</label>
          <input nbInput fullWidth [(ngModel)]="headTable.tia">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Paga IVA</label>
          <input nbInput fullWidth [(ngModel)]="headTable.pagaiva">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <label>Especies fiscales</label>
          <input nbInput fullWidth [(ngModel)]="headTable.especiesfiscales">
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;" *ngFor="let field of newFields">
          <label>{{field.name}}</label>
          <input nbInput fullWidth [(ngModel)]="field.text">
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 0.5rem;">
          <button nbButton ghost (click)="addFields()"><nb-icon icon="plus-circle-outline"></nb-icon> Agregar</button>
        </div>

      </div>
    </div>
  </nb-card-body>
  <nb-card-footer style="align-self: flex-end;">
    <button nbButton ghost (click)="update()"><nb-icon icon="save-outline"></nb-icon>Actualizar</button>
  </nb-card-footer>
</nb-card>
