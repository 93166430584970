<div class="row">
  <div class="col-3 col-sm-1"></div>
  <div class="col-3 col-sm-6">
  </div>
  <div class="col-3 col-sm-2"></div>
  <div class="col-6 col-sm-3" style="text-align: -webkit-center;">
    <nb-card class="card">
      <nb-card-header class="headercardline">
        <a><img src="../../../favicon.ico" style="width: 5rem !important;"></a>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-12" style="text-align: -webkit-center;">
            <div class="col input">
              <nb-form-field>
                <input type="text" nbInput placeholder="Correo electronico" fullWidth name="email" id="email" [(ngModel)]="userEmail" maxlength="50" required>
              </nb-form-field>
            </div>
            <div class="col input">
              <nb-form-field style="padding-bottom: 0.5rem !important;">
                <input [type]="getInputType()" nbInput fullWidth class="inputText" autocomplete="off" placeholder="Contraseña" name="password" id="password" [(ngModel)]="userPassword" maxlength="50" required>
                <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                  <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                           pack="eva"
                           [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                  </nb-icon>
                </button>
              </nb-form-field>
              <a class="forgot text-muted" href="#" style="margin-top: 1rem !important;">¿Olvidó su contraseña?</a>
            </div>
            <div class="col" style="text-align: center !important;">
              <button nbButton ghost type="button" style="color: white;border-color: aqua;" (click)="onSubmit()">Ingresar</button>
            </div>
          </div>
          <svg style="height: 0.05rem; background: black; margin-top: 1rem !important; margin-bottom: 1rem !important"></svg>
          <div class="col-12">
            <div class="row">
              <div class="col">
                <!-- <button nbButton ghost fullWidth><img width="30rem !important" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"></button> -->
              </div>
              <div class="col">
                <!-- <button nbButton ghost fullWidth><img width="30rem !important" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/1024px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"></button> -->
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>

