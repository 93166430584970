<nb-card>
  <nb-card-body>
    <!-- <ng2-smart-table [settings]="settings" [source]="headTable"></ng2-smart-table> -->
      <div class="col-sm-12 col-md-12 col-lg-12 table-responsive-xl">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <h3>Editar tabla de contrato</h3>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: end;">
            <button nbButton ghost (click)="createExcel()"><nb-icon icon="cloud-download-outline"></nb-icon> Exportar a </button>
          </div>
        </div>
        <table class="table table-striped table-bordered">
            <thead class="thead-dark">
                <tr style="text-align: -webkit-center;">
                    <th scope="col" width="20%">Empresa / Arrendante</th>
                    <th scope="col" width="20%">Parque</th>
                    <th scope="col" width="20%">Número de finca</th>
                    <th scope="col" width="20%">Localización</th>
                    <th scope="col" width="20%">Fiduciario</th>
                    <th scope="col" width="20%">M2 arrendados / Area arrendada</th>
                    <th scope="col" width="20%">Costo por metro cuadrado / Renta</th>
                    <th scope="col" width="20%">Moneda</th>
                    <th scope="col" width="20%">NIS - Agua</th>
                    <th scope="col" width="20%">NIS - Electricidad</th>
                    
                    

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
                    <td>{{p.empresaarrendante}}</td>
                    <td>{{p.parque}}</td>
                    <td>{{p.original_key_map.numerodefinca}}</td>
                    <td>{{p.localizacion}}</td>
                    <td>{{p.fiduciario}}</td>
                    <td>{{p.m2arrendadosareaarrendada}}</td>
                    <td>{{p.costopormetrocuadradorenta}}</td>
                    <td>{{p.moneda}}</td>
                    <td>{{p.nisagua}}</td>
                    <td>{{p.niselectricidad}}</td>
                    
                    
                </tr>
            </tbody>
            <thead class="thead-dark">
              <tr style="text-align: -webkit-center;">
                  <th scope="col" width="20%">Cliente / Arrendataria</th>
                  <th scope="col" width="20%">Cédula jurídica</th>
                  <th scope="col" width="20%">Contacto CxC</th>
                  <th scope="col" width="20%">Contacto Operación / Notificaciones</th>
                  <th scope="col" width="20%">Fecha de contrato</th>
                  <th scope="col" width="20%">Fecha de entrega</th>
                  <th scope="col" width="20%">Fecha inicio de cobro / Fecha inicio de renta</th>
                  <th scope="col" width="20%">Periodo de gracia</th>
                  <th scope="col" width="20%">Mes de aumento</th>
                  <th scope="col" width="20%">Aumento anual </th>
                  

              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
                <td>{{p.clientearrendataria}}</td>
                <td>{{p.cedulajuridica}}</td>
                <td>{{p.contactocxc}}</td>
                <td>{{p.contactooperacionnotificaciones}}</td>
                <td>{{p.fechadecontrato}}</td>
                <td>{{p.fechadeentrega}}</td>
                <td>{{p.fechainiciodecobrofechainicioderenta}}</td>
                <td>{{p.periododegracia}}</td>
                <td>{{p.mesdeaumento}}</td>
                <td>{{p.aumentoanual}}</td>
                
                
            </tr>
          </tbody>
            <thead class="thead-dark">
              <tr style="text-align: -webkit-center;">
                  <th scope="col" width="20%">Vencimiento / Plazo del Arrendamiento</th>
                  <th scope="col" width="20%">Plazo</th>
                  <th scope="col" width="20%">Depósito de garantía</th>
                  <th scope="col" width="20%">Monto de alquiler / Renta mensual</th>
                  <th scope="col" width="20%">Cuota de Mantenimiento / CAM</th>
                  <th scope="col" width="20%">Equipment Maintence Fee</th>
                  <th scope="col" width="20%">Additional Rent</th>
                  <th scope="col" width="20%">Brokers Commissions</th>
                  <th scope="col" width="20%">HVAC</th>
                  <th scope="col" width="20%">Parqueos /Estacionamientos</th>
                  
             
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
                <td>{{p.vencimientoplazodelarrendamiento}}</td>
                <td>{{p.plazo}}</td>
                <td>{{p.depositodegarantia}}</td>
                <td>{{p.montodealquilerrentamensual}}</td>
                <td>{{p.cuotademantenimientocam}}</td>
                <td>{{p.equipmentmaintencefee}}</td>
                <td>{{p.brokerscommissions}}</td>
                <td>{{p.periododegracia}}</td>
                <td>{{p.original_key_map.hvac}}</td>
                <td>{{p.parqueosiestacionamientos}}</td>
                
            </tr>
          </tbody>
          <thead class="thead-dark">
               <tr style="text-align: -webkit-center;">
                <th scope="col" width="20%">Monto de Oficinas</th>
                <th scope="col" width="20%">TIA</th>
                <th scope="col" width="20%">Paga IVA</th>
                <th scope="col" width="20%">Especies fiscales</th>
                <th scope="col" width="20%" *ngFor="let item of newFields1">{{item.name}}</th>

            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
              <td>{{p.montodeoficinas}}</td>
              <td>{{p.original_key_map.tia}}</td>
              <td>{{p.original_key_map.pagaiva}}</td>
              <td>{{p.original_key_map.especiesfiscales}}</td>
              <td scope="col" width="20%" *ngFor="let item of newFields1">{{item.text}}</td>
              
          </tr>
          
        </tbody>

        <thead class="thead-dark" *ngIf="newFields2.length > 0">
          <tr style="text-align: -webkit-center;" *ngFor="let p of headTable">
           
           <th scope="col" width="20%" *ngFor="let item of newFields2">{{item.name}}</th>

       </tr>
   </thead>
   <tbody *ngIf="newFields2.length > 0">
    <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
        
        <td scope="col" width="20%" *ngFor="let item of newFields2">{{item.text}}</td>
        
    </tr>
    
  </tbody>

  
  <thead class="thead-dark" *ngIf="newFields3.length > 0">
    <tr style="text-align: -webkit-center;" *ngFor="let p of headTable">
     
     <th scope="col" width="20%" *ngFor="let item of newFields3">{{item.name}}</th>

 </tr>
</thead>
<tbody *ngIf="newFields3.length > 0">
<tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
  
  <td scope="col" width="20%" *ngFor="let item of newFields3">{{item.text}}</td>
  
</tr>

</tbody>


        </table>
    </div>


    <div hidden="true">
      <table class="table table-striped table-bordered" id="excel-table">
          <thead class="thead-dark">
              <tr style="text-align: -webkit-center;">
                  <th scope="col" width="20%">Empresa / Arrendante</th>
                  <th scope="col" width="20%">Parque</th>
                  <th scope="col" width="20%">Número de finca</th>
                  <th scope="col" width="20%">Localización</th>
                  <th scope="col" width="20%">Fiduciario</th>
                  <th scope="col" width="20%">M2 arrendados / Area arrendada</th>
                  <th scope="col" width="20%">Costo por metro cuadrado / Renta</th>
                  <th scope="col" width="20%">Moneda</th>
                  <th scope="col" width="20%">NIS - Agua</th>
                  <th scope="col" width="20%">NIS - Electricidad</th>
                  <th scope="col" width="20%">Cliente / Arrendataria</th>
                  <th scope="col" width="20%">Cédula jurídica</th>
                  <th scope="col" width="20%">Contacto CxC</th>
                  <th scope="col" width="20%">Contacto Operación / Notificaciones</th>
                  <th scope="col" width="20%">Fecha de contrato</th>
                  <th scope="col" width="20%">Fecha de entrega</th>
                  <th scope="col" width="20%">Fecha inicio de cobro / Fecha inicio de renta</th>
                  <th scope="col" width="20%">Periodo de gracia</th>
                  <th scope="col" width="20%">Mes de aumento</th>
                  <th scope="col" width="20%">Aumento anual </th>
                  <th scope="col" width="20%">Vencimiento / Plazo del Arrendamiento</th>
                  <th scope="col" width="20%">Plazo</th>
                  <th scope="col" width="20%">Depósito de garantía</th>
                  <th scope="col" width="20%">Monto de alquiler / Renta mensual</th>
                  <th scope="col" width="20%">Cuota de Mantenimiento / CAM</th>
                  <th scope="col" width="20%">Equipment Maintence Fee</th>
                  <th scope="col" width="20%">Additional Rent</th>
                  <th scope="col" width="20%">Brokers Commissions</th>
                  <th scope="col" width="20%">HVAC</th>
                  <th scope="col" width="20%">Parqueos /Estacionamientos</th>
                  <th scope="col" width="20%">Monto de Oficinas</th>
                  <th scope="col" width="20%">TIA</th>
                  <th scope="col" width="20%">Paga IVA</th>
                  <th scope="col" width="20%">Especies fiscales</th>
                 
                  <th scope="col" width="20%" *ngFor="let item of newFields">{{item.name}}</th>
               

              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let p of headTable" style="text-align: -webkit-center;">
                  <td>{{p.empresaarrendante}}</td>
                  <td>{{p.parque}}</td>
                  <td>{{p.original_key_map.numerodefinca}}</td>
                  <td>{{p.localizacion}}</td>
                  <td>{{p.fiduciario}}</td>
                  <td>{{p.m2arrendadosareaarrendada}}</td>
                  <td>{{p.costopormetrocuadradorenta}}</td>
                  <td>{{p.moneda}}</td>
                  <td>{{p.nisagua}}</td>
                  <td>{{p.niselectricidad}}</td>
                  <td>{{p.clientearrendataria}}</td>
                  <td>{{p.cedulajuridica}}</td>
                  <td>{{p.contactocxc}}</td>
                  <td>{{p.contactooperacioninotificaciones}}</td>
                  <td>{{p.fechadecontrato}}</td>
                  <td>{{p.fechadeentrega}}</td>
                  <td>{{p.fechainiciodecobrofechainicioderenta}}</td>
                  <td>{{p.periododegracia}}</td>
                  <td>{{p.mesdeaumento}}</td>
                  <td>{{p.aumentoanual}}</td>
                  <td>{{p.vencimientoplazodelarrendamiento}}</td>
                  <td>{{p.plazo}}</td>
                  <td>{{p.depositodegarantia}}</td>
                  <td>{{p.montodealquilerrentamensual}}</td>
                  <td>{{p.cuotademantenimientocam}}</td>
                  <td>{{p.equipmentmaintencefee}}</td>
                  <td>{{p.brokerscommissions}}</td>
                  <td>{{p.periododegracia}}</td>
                  <td>{{p.original_key_map.hvac}}</td>
                  <td>{{p.parqueosiestacionamientos}}</td>
                  <td>{{p.montodeoficinas}}</td>
                  <td>{{p.original_key_map.tia}}</td>
                  <td>{{p.original_key_map.pagaiva}}</td>
                  <td>{{p.original_key_map.especiesfiscales}}</td>
                  
                  <td *ngFor="let item of newFields">{{item.text}}</td>
              </tr>
          </tbody>
        </table>
    </div>
  </nb-card-body>
  <nb-card-footer style="align-self: flex-end;">
    <button nbButton ghost style="color: blue;" (click)="editTable()">Editar tabla</button>
  </nb-card-footer>
</nb-card>

<div class="reportLogo" style="display: none">
  <img src="../../../assets/images/core/LogoMontecristo.png">
  <img src="../../../assets/images/core/mContract.png">
</div>
