import { TableEditComponent } from './views/head-table-contract/table-edit/table-edit.component';
import { HeadTableContractComponent } from './views/head-table-contract/head-table-contract.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/services/auth-guard.service';
import { LoginComponent } from './core/auth/login/login.component';
import { UserAutoConfirmComponent } from './core/auth/user-auto-confirm/user-auto-confirm.component';
import { UserCreationComponent } from './views/Administracion/user-creation/user-creation.component';
import { ContractFormatsComponent } from './views/Catalogos/contract-formats/contract-formats.component';
import { ContractOneViewComponent } from './views/Catalogos/contract-one-view/contract-one-view.component';
import { ContractUploadComponent } from './views/Catalogos/contract-upload/contract-upload.component';
import { ClientsPComponent } from './views/ProcesamientoDeContratos/clients/clients.component';
import { ServicesComponent } from './views/ProcesamientoDeContratos/services/services.component';
import { UnitsToLeaseComponent } from './views/ProcesamientoDeContratos/units-to-lease/units-to-lease.component';
import { ClientsComponent } from './views/Reporteria/clients/clients.component';
import { ContractsExpirationsComponent } from './views/Reporteria/contracts-expirations/contracts-expirations.component';
import { MonthlyBillingComponent } from './views/Reporteria/monthly-billing/monthly-billing.component';
import { UnitsForRentComponent } from './views/Reporteria/units-for-rent/units-for-rent.component';
import { ContractSettingsComponent } from './views/Administracion/contract-settings/contract-settings.component';
import { FolderComponent } from './views/folder/folder.component';
import { FolderViewComponent } from './views/folder/folder-view/folder-view.component';

const routes: Routes = [
  {path: 'AdministracionDeUsuarios', canActivate: [AuthGuard], component: UserCreationComponent},
  {path: 'userAutoConfirm/:userEmail/:verificationCode', component: UserAutoConfirmComponent},
  {path: 'CargaDeContratos', canActivate: [AuthGuard], component: ContractUploadComponent},
  {path: 'FormatoDeContratos', canActivate: [AuthGuard], component: ContractFormatsComponent},
  {path: 'Clientes', canActivate: [AuthGuard], component: ClientsPComponent},
  {path: 'UnidadesParaArrendar', canActivate: [AuthGuard], component: UnitsToLeaseComponent},
  {path: 'Servicios', canActivate: [AuthGuard], component: ServicesComponent},
  {path: 'FacturacionDelMes', canActivate: [AuthGuard], component: MonthlyBillingComponent},
  {path: 'VencimientoDeContratos', canActivate: [AuthGuard], component: ContractsExpirationsComponent},
  {path: 'ReporteClientes', canActivate: [AuthGuard], component: ClientsComponent},
  {path: 'UnidadesParaRenta', canActivate: [AuthGuard], component: UnitsForRentComponent},
  {path: 'Analisis/:contractUUID', canActivate: [AuthGuard], component: ContractOneViewComponent},
  {path: 'Login', canActivate: [AuthGuard], component: LoginComponent},
  {path: 'headContractTable/:contractUUID', canActivate: [AuthGuard], component: HeadTableContractComponent},
  {path: 'editContractTable/:contractUUID', canActivate: [AuthGuard], component: TableEditComponent},
  {path: 'contractSettings', canActivate: [AuthGuard], component: ContractSettingsComponent},
  {path: 'folders', canActivate: [AuthGuard], component: FolderComponent},
  {path: 'foldersView/:folderId', canActivate: [AuthGuard], component: FolderViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
