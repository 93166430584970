import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContractStatusModel } from 'src/app/models/contractStatusModel';
import * as S3 from 'aws-sdk/clients/s3';



@Injectable({
  providedIn: 'root'
})

export class ContractService {

  constructor(
    private http: HttpClient,
  ){}

  async upload(contractUUID,file): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    // const params = {
    //   Body: image,
    //   ContentEncoding: 'base64',
    //   ContentType: 'application/pdf'
    // };

    //Nuevos parametros
    const params = {
      s3bucket: environment.bucketName,
      s3key: 'contracts' + '/' + contractUUID + '/' + file.name,
    };
    // console.log(image);
    return this.http.post(environment.API_URL + 'poc' + '/contract/s3', params, {headers}).toPromise();
  }

  contractFinish(UUID){

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    return this.http.get(environment.API_URL + 'poc' + '/contract/' + UUID, {headers}).toPromise();
  }

  getFromElastic(UUID){

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    return this.http.get(environment.API_URL + 'poc' + '/contract/' + UUID, {headers}).toPromise();
  }

  contractTableUpdate(data): Promise<any>{
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    const params = {
      Body: data,
    };

    return this.http.post(environment.API_URL + 'poc' + '/field/', params, {headers}).toPromise();
  }

  elasticSearch(text){

    const params = {
      // allWords: text.split(' ')
      textToSearch: text
    }
console.log(params);
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    return this.http.post(environment.API_URL + 'poc' + '/searchContract', params, {headers}).toPromise();
  }
  elasticSearchByPhrase(text){

    const params = {
      allWords: text.split(' ')
      // textToSearch: text
    }
console.log(params);
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    return this.http.post(environment.API_URL + 'poc' + '/searchContract', params, {headers}).toPromise();
  }

  newField(fieldName,fieldContractId,fieldValue){

    const params = {Body:{
      contractId:fieldContractId,
      custom_field: {
        field_name:fieldName,
        field_value:fieldValue,
        field_type: "string"
      }
    }
    }
    // console.log(params);

    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' + btoa('admin:secret')
    });

    return this.http.post(environment.API_URL + 'poc' + '/field/custom', params, {headers}).toPromise();
  }


  contractStatusTableInsert(contract: ContractStatusModel): Promise<any> {
    let params = JSON.stringify(contract);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URLT + 'mcontract-contract', params, { headers}).toPromise();
  }

  contractStatusGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URLT + 'mcontract-contract', { headers}).toPromise();
  }

  contractUpdateStatus(contractUUID,contractStatus,contractDay): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      contractUUID: contractUUID,
      contractStatus: contractStatus,
      contractDay:contractDay,
    }

    return this.http.put(environment.API_URLT + 'mcontract-contract', params, { headers}).toPromise();
  }

  contractUpdateDays(contractDay): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      days:contractDay,
    }

    return this.http.put(environment.API_URLT + 'mcontract-contract/dayupdate', params, { headers}).toPromise();
  }

  contractDelete(contract): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      uuid:contract,
    }

    return this.http.put(environment.API_URLT + 'mcontract-contract/contractDelete', params, { headers}).toPromise();
  }

  contractUpdateDateFinish(contractUUID,dateFinish): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      contractUUID: contractUUID,
      dateFinish: dateFinish
    }

    return this.http.put(environment.API_URLT + 'mcontract-contract/update', params, { headers}).toPromise();
  }

  uploadFileS3(file, contractUUID): Promise<any> {
    // this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'contracts' + '/' + contractUUID + '/' + file.name,
      Body: file,
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        // this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        // data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  contractGetOne(contractName){
    const filter = contractName;
    return this.http.get(environment.API_URLT + 'mcontract-contract' + '/one/' + filter).toPromise();
  }

  contractGetOneById(contractUUID){
    const filter = contractUUID;
    return this.http.get(environment.API_URLT + 'mcontract-contract' + '/oneId/' + filter).toPromise();
  }



}
