import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbMenuModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbSidebarModule, NbToggleModule, NbCheckboxModule, NbToastrModule, NbWindowModule, NbDialogModule, NbUserModule, NbSelectModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { UserCreationComponent } from './views/Administracion/user-creation/user-creation.component';
import { ContractUploadComponent } from './views/Catalogos/contract-upload/contract-upload.component';
import { ContractFormatsComponent } from './views/Catalogos/contract-formats/contract-formats.component';
import { ClientsPComponent } from './views/ProcesamientoDeContratos/clients/clients.component';
import { UnitsToLeaseComponent } from './views/ProcesamientoDeContratos/units-to-lease/units-to-lease.component';
import { ServicesComponent } from './views/ProcesamientoDeContratos/services/services.component';
import { MonthlyBillingComponent } from './views/Reporteria/monthly-billing/monthly-billing.component';
import { ContractsExpirationsComponent } from './views/Reporteria/contracts-expirations/contracts-expirations.component';
import { UnitsForRentComponent } from './views/Reporteria/units-for-rent/units-for-rent.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ClientsComponent } from './views/Reporteria/clients/clients.component';
import { HttpClientModule } from '@angular/common/http';
import { ContractOneViewComponent } from './views/Catalogos/contract-one-view/contract-one-view.component';
import { LoadingComponent } from './core/loading/loading.component';
import { LoadingWrapperComponent } from './core/loading-wrapper/loading-wrapper.component';
import { LoadingWrapperOverlayComponent } from './core/loading-wrapper-overlay/loading-wrapper-overlay.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './views/Catalogos/pdf-viewer/pdf-viewer.component';
import { LoginComponent } from './core/auth/login/login.component';
import { AuthService } from 'src/services/auth.service';
import { AuthGuard } from 'src/services/auth-guard.service';
import { HeadTableContractComponent } from './views/head-table-contract/head-table-contract.component';
import { TableEditComponent } from './views/head-table-contract/table-edit/table-edit.component';
import { ContractSettingsComponent } from './views/Administracion/contract-settings/contract-settings.component';
import { FilterContractsPipe } from './pipe/filter-contracts.pipe';
import { FolderComponent } from './views/folder/folder.component';
import { FolderViewComponent } from './views/folder/folder-view/folder-view.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    UserCreationComponent,
    ContractUploadComponent,
    ContractFormatsComponent,
    ClientsPComponent,
    UnitsToLeaseComponent,
    ServicesComponent,
    LoadingComponent,
    LoadingWrapperComponent,
    LoadingWrapperOverlayComponent,
    MonthlyBillingComponent,
    ContractsExpirationsComponent,
    UnitsForRentComponent,
    ClientsComponent,
    ContractOneViewComponent,
    PdfViewerComponent,
    LoginComponent,
    HeadTableContractComponent,
    TableEditComponent,
    ContractSettingsComponent,
    FilterContractsPipe,
    FolderComponent,
    FolderViewComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbToggleModule,
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NgxExtendedPdfViewerModule,
    NbCheckboxModule,
    PdfViewerModule,
    NbInputModule,
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbFormFieldModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    NbUserModule,
    NbSelectModule,
  ],
  providers: [AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
