<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="card">
  <nb-card-header>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <h1> <img src="../../../../assets/images/profile-user.png" style="width: 3rem !important;"> Crear usuario</h1>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <label >Primer Nombre</label>
        <input type="text" nbInput fullWidth  id="userFirstName" placeholder="Primer Nombre" [(ngModel)]="user.userFirstName">
        <label *ngIf="!user.userFirstName" style="color: red;"><nb-icon icon="alert-triangle-outline" style="vertical-align: middle;"></nb-icon> Campo requerido</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <label >Apellido</label>
        <input type="text" nbInput fullWidth  id="userLastName" placeholder="Apellido" [(ngModel)]="user.userLastName">
        <label *ngIf="!user.userLastName" style="color: red;"><nb-icon icon="alert-triangle-outline" style="vertical-align: middle;"></nb-icon> Campo requerido</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <label >Correo</label>
        <input type="text" nbInput fullWidth  id="userEmail" placeholder="Correo electrónico" [(ngModel)]="user.userEmail">
        <label *ngIf="!user.userEmail" style="color: red;"><nb-icon icon="alert-triangle-outline" style="vertical-align: middle;"></nb-icon> Campo requerido</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <label>Rol de usuario</label>
        <nb-select placeholder="Seleccione Rol" fullWidth [(ngModel)]="user.userRole"
            required maxlength="0">
            <nb-option [value]="userRole.value" *ngFor="let userRole of userRoleList">{{userRole.type}}
            </nb-option>
        </nb-select>
        <label *ngIf="!user.userEmail" style="color: red;"><nb-icon icon="alert-triangle-outline" style="vertical-align: middle;"></nb-icon> Campo requerido</label>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 inputs" *ngIf="user.userFirstName && user.userLastName && user.userEmail">
        <nb-checkbox (checkedChange)="toggle($event)">Confirmar creación de usuario</nb-checkbox>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <button *ngIf="checked" type="submit" nbButton style="background-color: #2bb52b80;border-color: transparent;" (click)="saveUser()">Crear usuario</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
