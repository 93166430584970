import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '../app/models/userModel';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  constructor(
    private http: HttpClient,
  ) {}

  newFolder(folder): Promise<any> {
    let params = JSON.stringify(folder);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URLT + 'mcontract-folders/', params, { headers}).toPromise();
  }
  folderGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(environment.API_URLT + 'mcontract-folders/', { headers}).toPromise();
  }
  folderGetContracts(id): Promise<any> {
    const filter: string = id;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URLT + 'mcontract-folders/oneId/'+filter, { headers}).toPromise();
  }
  folderAddContract(folderId,contractUUID): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
        folderId: folderId,
        contractUUID: contractUUID,
    }

    return this.http.put(environment.API_URLT + 'mcontract-folders/', params, { headers}).toPromise();
  }


  folderDelete(folderId): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = {
      uuid: folderId
    }

    return this.http.put(environment.API_URLT + 'mcontract-folders/folderDelete', params, { headers}).toPromise();
  }

  
}
