import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractService } from 'src/services/contract.service';
import { ContractStatusModel } from 'src/app/models/contractStatusModel';
import contractStatus from '../../../../assets/tables/contractStatus.json';
import filteredContracts from '../../../../assets/tables/filteredContracts.json';
import { Router } from '@angular/router';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
const uuid = require('uuid');
@Component({
  selector: 'app-contract-upload',
  templateUrl: './contract-upload.component.html',
  styleUrls: ['./contract-upload.component.scss']
})
export class ContractUploadComponent implements OnInit {

  contract = {} as ContractStatusModel;
  settings = contractStatus;
  settingsf = filteredContracts;
  contractStatusList = new Array();
  filteredContractList = new Array();
  loading = false;
  

  @ViewChild('labelImport')
  labelImport: ElementRef;

  formImport: FormGroup;
  fileToUpload: File = null;
  base64;
  text;

  constructor(
    private contractservice: ContractService,
    private toastrService: NbToastrService,
    private router: Router,
  ) {
    this.formImport = new FormGroup({
      importFile: new FormControl('', Validators.required)
   });
   }

  ngOnInit(){
    this.loading = true;
    this.listContracts();
  }

  preUpload(){
    this.contractservice.contractGetOne(this.fileToUpload.name).then(
      (response: any) => {
        const contractDB = JSON.parse(response).records[0];
        if (contractDB === undefined){
          this.upload();
        } else if (contractDB !== undefined){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'warning', 'Contrato duplicado', { position, status });
        }
      },
      (error: any) => {
      }
    )
  }


  async upload(): Promise<any>{
    this.loading = true;
    var f = this.fileToUpload; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = () => {
        // var binaryData = reader.result;
       
        // //Converting Binary Data to base 64
        // const base64String = window.btoa(binaryData.toString());
        // this.contractservice.upload(base64String).then(
        //   response => {
        //     this.contract.contractName = this.fileToUpload.name;
        //     this.contract.contractUUID = response.id;
        //     this.contract.contractStatus = response.status;
        //     this.contractservice.uploadFileS3(this.fileToUpload, response.id).then(
        //       (response: any) => {
        //         this.contract.contractPDFS3 = response.Location;
        //         this.contractservice.contractStatusTableInsert(this.contract).then(
        //           response => {
        //             const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        //             const status: NbComponentStatus = 'success';
        //             this.toastrService.show(status || '', 'Contrato cargado al sistema.', { position, status });
        //             this.loading = false;
        //             this.listContracts();
        //           },
        //           error => {
        //           }
        //         )
        //       },
        //       (error: any) => {
        //       }
        //     )
        //   },
        //   err => {
        //   }
        // )


        // Cambio de ruta para almacenamiento
        var binaryData = reader.result;
       
        //Converting Binary Data to base 64
        const base64String = window.btoa(binaryData.toString());
        const contractCode = uuid.v4();
        this.contractservice.uploadFileS3(this.fileToUpload, contractCode).then(
          response => {
            this.contract.contractPDFS3 = response.Location;
            this.contractservice.upload(contractCode,this.fileToUpload).then(
              (response: any) => {
               
                this.contract.contractName = this.fileToUpload.name;
                this.contract.contractUUID = response.id;
                this.contract.contractStatus = response.status;
               
                this.contractservice.contractStatusTableInsert(this.contract).then(
                  response => {
                    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status: NbComponentStatus = 'success';
                    this.toastrService.show(status || '', 'Contrato cargado al sistema.', { position, status });
                    this.loading = false;
                    this.listContracts();
                  },
                  error => {
                    console.log(error);
                  }
                )
              },
              (error: any) => {
              }
            )
          },
          err => {
          }
        )
    };
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

   listContracts(){
    this.loading = true;
    this.contractStatusList = new Array();
    this.contractservice.contractStatusGetAll().then(
      response => {
        const contractDB = response.records[0][0];
        this.contractStatusList = contractDB;
        contractDB.forEach(element => {
          if (element.contractStatus === 'ENQUEUED'){
            const UUID = element.contractUUID;
            this.contractservice.contractFinish(UUID).then(
              (response: any) => {
                if (response.status === 'COMPLETE'){
                  const contractUUID = response.id;
                  const contractStatus = response.status;
                  this.contractservice.contractUpdateStatus(contractUUID,contractStatus,null).then(
                    (response: any) => {
                    },
                    error => {
                    }
                  )
                }
              },
              error => {
              }
            )
          }
        });
        this.loading = false;
      }
    )
  }



  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }

  import(): void {
    console.log('import ' + this.fileToUpload.name);
  }

  onUserRowSelect(event){
    const UUID = event.data.contractPDFS3;
    window.open(UUID, '_blank');
  }
}
