import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from 'src/services/contract.service';
import { HeadTableModel } from 'src/app/models/headTableModel';
import * as xlsx from 'xlsx';
import Swal from 'sweetalert2';
const uuid = require('uuid');

@Component({
  selector: 'app-table-edit',
  templateUrl: './table-edit.component.html',
  styleUrls: ['./table-edit.component.scss']
})
export class TableEditComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  // headTable = new Array<any>();
  loading = false;
  headTable = {} as HeadTableModel;
  newFields ;
  @Input() reportName: string;
  contractUUID;
  tableTemp = [];
  constructor(
    private contractService: ContractService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(){
    this.loading = true;
    await this.route.paramMap.subscribe((params: any) => {
      this.contractUUID = params.params.contractUUID;
      this.getTable(params.params.contractUUID);
    });
  }

  saveNewValue(){
    var contador = 0;
    this.loading = true;
    if (this.newFields[contador] != undefined && this.newFields[contador] != null) {

      this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
        
        contador++;
           if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                
              contador++;
                 if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                  this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                    
                    contador++;
                       if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                            
                          contador++;
                             if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                              this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                  
                                contador++;
                                   if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                     
                                      contador++;

                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                           
                                          contador++;
                                          
                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                
                                              contador++;
                                              
                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                    
                                                  contador++;
                                                  
                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                        
                                                      contador++;
                                                      
                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                           
                                                          contador++;
                                                          
                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                
                                                              contador++;
                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                     
                                                                  contador++;
                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                         
                                                                      contador++;
                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                            
                                                                          contador++;
                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                
                                                                              contador++;
                                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                    
                                                                                  contador++;
                                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                        
                                                                                      contador++;
                                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                           
                                                                                          contador++;
                                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                               
                                                                                              contador++;
                                                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                   
                                                                                                  contador++;
                                                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                         
                                                                                                      contador++;
                                                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                            
                                                                                                          contador++;
                                                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                
                                                                                                              contador++;
                                                                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                    
                                                                                                                  contador++;
                                                                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                        
                                                                                                                      contador++;
                                                                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                           
                                                                                                                          contador++;
                                                                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                               
                                                                                                                              contador++;
                                                                                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                    
                                                                                                                                  contador++;
                                                                                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                        
                                                                                                                                      contador++;
                                                                                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                             
                                                                                                                                          contador++;
                                                                                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                                
                                                                                                                                              contador++;
                                                                                                                                              if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                                this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                                   
                                                                                                                                                  contador++;
                                                                                                                                                  if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                                    this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                                      
                                                                                                                                                      contador++;
                                                                                                                                                      if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                                        this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                                            
                                                                                                                                                          contador++;
                                                                                                                                                          if (this.newFields[contador] != undefined && this.newFields[contador] != null) {
                                                                                                                                                            this.contractService.newField(this.newFields[contador].name,this.contractUUID,this.newFields[contador].text).then(response=>{
                                                                                                                                                                 
                                                                                                                                                              contador++;
                                                                                                                                                              
                                                                                                                                                             });
                                                                                                                                                          }else{
                                                                                                                                                            this.loading = false;
                                                                                                                                                            this.message();
                                                                                                                                                          }
                                                                                                                                                          
                                                                                                                                                         });
                                                                                                                                                      }else{
                                                                                                                                                        this.loading = false;
                                                                                                                                                        this.message();
                                                                                                                                                      }
                                                                                                                                                      
                                                                                                                                                     });
                                                                                                                                                  }else{
                                                                                                                                                    this.loading = false;
                                                                                                                                                    this.message();
                                                                                                                                                  }
                                                                                                                                                  
                                                                                                                                                 });
                                                                                                                                              }else{
                                                                                                                                                this.loading = false;
                                                                                                                                                this.message();
                                                                                                                                              }
                                                                                                                                              
                                                                                                                                             });
                                                                                                                                          }else{
                                                                                                                                            this.loading = false;
                                                                                                                                            this.message();
                                                                                                                                          }
                                                                                                                                          
                                                                                                                                         });
                                                                                                                                      }else{
                                                                                                                                        this.loading = false;
                                                                                                                                        this.message();
                                                                                                                                      }
                                                                                                                                      
                                                                                                                                     });
                                                                                                                                  }else{
                                                                                                                                    this.loading = false;
                                                                                                                                    this.message();
                                                                                                                                  }
                                                                                                                                  
                                                                                                                                 });
                                                                                                                              }else{
                                                                                                                                this.loading = false;
                                                                                                                                this.message();
                                                                                                                              }
                                                                                                                              
                                                                                                                             });
                                                                                                                          }else{
                                                                                                                            this.loading = false;
                                                                                                                            this.message();
                                                                                                                          }
                                                                                                                          
                                                                                                                         });
                                                                                                                      }else{
                                                                                                                        this.loading = false;
                                                                                                                        this.message();
                                                                                                                      }
                                                                                                                      
                                                                                                                     });
                                                                                                                  }else{
                                                                                                                    this.loading = false;
                                                                                                                    this.message();
                                                                                                                  }
                                                                                                                  
                                                                                                                 });
                                                                                                              }else{
                                                                                                                this.loading = false;
                                                                                                                this.message();
                                                                                                              }
                                                                                                              
                                                                                                             });
                                                                                                          }else{
                                                                                                            this.loading = false;
                                                                                                            this.message();
                                                                                                          }
                                                                                                          
                                                                                                         });
                                                                                                      }else{
                                                                                                        this.loading = false;
                                                                                                        this.message();
                                                                                                      }
                                                                                                      
                                                                                                     });
                                                                                                  }else{
                                                                                                    this.loading = false;
                                                                                                    this.message();
                                                                                                  }
                                                                                                  
                                                                                                 });
                                                                                              }else{
                                                                                                this.loading = false;
                                                                                                this.message();
                                                                                              }
                                                                                              
                                                                                             });
                                                                                          }else{
                                                                                            this.loading = false;
                                                                                            this.message();
                                                                                          }
                                                                                          
                                                                                         });
                                                                                      }else{
                                                                                        this.loading = false;
                                                                                        this.message();
                                                                                      }
                                                                                      
                                                                                     });
                                                                                  }else{
                                                                                    this.loading = false;
                                                                                    this.message();
                                                                                  }
                                                                                  
                                                                                 });
                                                                              }else{
                                                                                this.loading = false;
                                                                                this.message();
                                                                              }
                                                                              
                                                                             });
                                                                          }else{
                                                                            this.loading = false;
                                                                            this.message();
                                                                          }
                                                                          
                                                                         });
                                                                      }else{
                                                                        this.loading = false;
                                                                        this.message();
                                                                      }
                                                                      
                                                                     });
                                                                  }else{
                                                                    this.loading = false;
                                                                    this.message();
                                                                  }
                                                                  
                                                                 });
                                                              }else{
                                                                this.loading = false;
                                                                this.message();
                                                              }
                                                              
                                                             });
                                                          }else{
                                                            this.loading = false;
                                                            this.message();
                                                          }

                                                         });
                                                      }else{
                                                        this.loading = false;
                                                        this.message();
                                                      }

                                                     });
                                                  }else{
                                                    this.loading = false;
                                                    this.message();
                                                  }

                                                 });
                                              }else{
                                                this.loading = false;
                                                this.message();
                                              }

                                             });
                                          }else{
                                            this.loading = false;
                                            this.message();
                                          }

                                         });
                                      }else{
                                        this.loading = false;
                                        this.message();
                                      }


                                      
                                      
                                     });
                                  }else{
                                    this.loading = false;
                                    this.message();
                                  }
                               });
                            }else{
                              this.loading = false;
                              this.message();
                            }
                         });
                      }else{
                        this.loading = false;
                        this.message();
                      }
                   });
                }else{
                  this.loading = false;
                  this.message();
                }

             });
          }else{
            this.loading = false;
            this.message();
          }

       });
    }else{
      this.loading = false;
      this.message();
    }
    
    

      
    
    
    // if (this.newFields != undefined) {
    //   this.newFields.forEach(element => {
      
      
      
    //     // fieldName,fieldContractId,fieldValue
    //     if (element.text == undefined) {
  
    //       element.text="";
    //       this.contractService.newField(element.name,this.contractUUID,element.text).then(response=>{
       
    //       });
    //     }else{
    //       this.contractService.newField(element.name,this.contractUUID,element.text).then(response=>{
       
    //       });
    //     }
        
        
    //   });
    // }
   
  }
  getTable(params){
    this.contractService.contractFinish(params).then(
      (result: any) => {
     
        const res =  Object.assign([],result.custom_fields);
      
        this.tableTemp = Object.assign([],result.custom_fields) ;
        this.newFields =Object.assign([],result.custom_fields);
        if (this.newFields!= undefined && this.newFields!= null) {
          this.newFields.forEach(element => {
            const fieldKeys = Object.keys(element);
            fieldKeys.forEach(key =>{
              
              element.value = key;
              element.name= key;
              element.text = element[key];
            })
            
          });
        }
        
        
        this.headTable = result.index_card;
      
        this.loading = false;
      }
    );
  }


  addFields(){
    
    Swal
    .fire({
        title: "Ingrese el Nombre del nuevo campo",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Crear",
        confirmButtonColor: '#004F9F',
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#898989",
        
    })
    .then(resultado => {
        if (resultado.value) {
            const value= resultado.value;
            let pass = resultado.value;
            pass = pass.replace(/ /g, "");
            pass = pass.toLowerCase();
            pass= quitarAcentos(pass);
           
            
            // fieldName,fieldContractId,fieldValue
            this.contractService.newField(pass,this.contractUUID,"").then(response=>{
          
              window.location.reload();
            });
        }
    });

    
  }

message(){
  Swal.fire(
    'Datos de tabla actualizados',
    '',
    'success'
  )
}
 async update(){
    Swal.fire({
      title: 'Actualización de tabla de contrato',
      text: "¿Esta seguro de actualizar la tabla del contrato?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Actualizar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const contractUUID = this.headTable;
        
if (contractUUID.additionalrent == undefined) {
  contractUUID.additionalrent = null;
}
if (contractUUID.empresaarrendante === undefined) {
  contractUUID.empresaarrendante = null;
}
if (contractUUID.parque === undefined) {
  contractUUID.parque = null;
}
if (contractUUID.numerodefinca === undefined) {
  contractUUID.numerodefinca = null;
}
if (contractUUID.localizacion === undefined) {
  contractUUID.localizacion = null;
}  
if (contractUUID.fiduciario === undefined) {
  contractUUID.fiduciario = null;
}
if (contractUUID.m2arrendadosareaarrendada === undefined) {
  contractUUID.m2arrendadosareaarrendada = null;
}
if (contractUUID.costopormetrocuadradorenta === undefined) {
  contractUUID.costopormetrocuadradorenta = null;
}
if (contractUUID.nisagua === undefined) {
  contractUUID.nisagua = null;
}
if (contractUUID.niselectricidad === undefined) {
  contractUUID.niselectricidad = null;
}
if (contractUUID.cedulajuridica === undefined) {
  contractUUID.cedulajuridica = null;
}
if (contractUUID.contactocxc === undefined) {
  contractUUID.contactocxc = null;
}
if (contractUUID.contactooperacioninotificaciones === undefined) {
  contractUUID.contactooperacioninotificaciones = null;
}
if (contractUUID.fechadecontrato === undefined) {
  contractUUID.fechadecontrato = null;
}
if (contractUUID.fechadeentrega === undefined) {
  contractUUID.fechadeentrega = null;
}
if (contractUUID.fechainiciodecobrofechainicioderenta === undefined) {
  contractUUID.fechainiciodecobrofechainicioderenta = null;
}
if (contractUUID.periododegracia === undefined) {
  contractUUID.periododegracia = null;
}
if (contractUUID.aumentoanual === undefined) {
  contractUUID.aumentoanual = null;
}
if (contractUUID.vencimientoplazodelarrendamiento === undefined) {
  contractUUID.vencimientoplazodelarrendamiento = null;
}
if (contractUUID.plazo === undefined) {
  contractUUID.plazo = null;
}
if (contractUUID.depositodegarantia === undefined) {
  contractUUID.depositodegarantia = null;
}
if (contractUUID.montodealquilerrentamensual === undefined) {
  contractUUID.montodealquilerrentamensual = null;
}
if (contractUUID.cuotademantenimientocam === undefined) {
  contractUUID.cuotademantenimientocam = null;
}
if (contractUUID.equipmentmaintencefee === undefined) {
  contractUUID.equipmentmaintencefee = null;
}
if (contractUUID.brokerscommissions === undefined) {
  contractUUID.brokerscommissions = null;
}
if (contractUUID.hvac === undefined) {
  contractUUID.hvac = null;
}
if (contractUUID.parqueosiestacionamientos === undefined) {
  contractUUID.parqueosiestacionamientos = null;
}
if (contractUUID.montodeoficinas === undefined) {
  contractUUID.montodeoficinas = null;
}
if (contractUUID.tia === undefined) {
  contractUUID.tia = null;
}
if (contractUUID.pagaiva === undefined) {
  contractUUID.pagaiva = null;
}
if (contractUUID.especiesfiscales === undefined) {
  contractUUID.especiesfiscales = null;
}
if (contractUUID.moneda === undefined) {
  contractUUID.moneda = null;
}
if (contractUUID.clientearrendataria === undefined) {
  contractUUID.clientearrendataria = null;
}
if (contractUUID.parqueosestacionamientos === undefined) {
  contractUUID.parqueosestacionamientos = null;
}
if (contractUUID.mesdeaumento === undefined) {
  contractUUID.mesdeaumento = null;
}
if (contractUUID.fechainiciodecobrofechainicioderenta === undefined) {
  contractUUID.fechainiciodecobrofechainicioderenta = null;
}
if (contractUUID.contactooperacionnotificaciones === undefined) {
  contractUUID.contactooperacionnotificaciones = null;
}

let contractUUID1 = Object.assign({},contractUUID);
contractUUID1.additionalrent= "Additional Rent";
contractUUID1.aumentoanual= "Aumento anual (%)";
contractUUID1.brokerscommissions= "Brokers Commissions";
contractUUID1.cedulajuridica= "Cédula jurídica";
contractUUID1.clientearrendataria= "Cliente / Arrendataria";
contractUUID1.contactocxc= "Contacto CxC";
contractUUID1.contactooperacionnotificaciones= "Contacto Operación / Notificaciones";
contractUUID1.costopormetrocuadradorenta= "Costo por metro cuadrado / Renta";
contractUUID1.cuotademantenimientocam="Cuota de Mantenimiento / CAM";
contractUUID1.depositodegarantia= "Depósito de garantía";
contractUUID1.empresaarrendante= "Empresa / Arrendante";
contractUUID1.equipmentmaintencefee= "Equipment Maintence Fee";
contractUUID1.especiesfiscales= "Especies fiscales";
contractUUID1.fechadecontrato= "Fecha de contrato";
contractUUID1.fechadeentrega= "Fecha de entrega";
contractUUID1.fechainiciodecobrofechainicioderenta= "Fecha inicio de cobro / Fecha inicio de renta";
contractUUID1.fiduciario="Fiduciario";
contractUUID1.hvac= "HVAC";
contractUUID1.localizacion= "Localización";
contractUUID1.m2arrendadosareaarrendada= "M2 arrendados / Area arrendada";
contractUUID1.mesdeaumento= "Mes de aumento";
contractUUID1.moneda= "Moneda";
contractUUID1.montodealquilerrentamensual= "Monto de alquiler / Renta mensual";
contractUUID1.montodeoficinas= "Monto de Oficinas";
contractUUID1.nisagua= "NIS - Agua";
contractUUID1.niselectricidad= "NIS - Electricidad";
contractUUID1.numerodefinca= "Número de finca";
contractUUID1.pagaiva= "Paga IVA";
contractUUID1.parque= "Parque";
contractUUID1.parqueosestacionamientos= "Parqueos/Estacionamientos";
contractUUID1.periododegracia= "Periodo de gracia";
contractUUID1.plazo= "Plazo";
contractUUID1.tia= "TIA";
contractUUID1.vencimientoplazodelarrendamiento= "Vencimiento / Plazo del Arrendamiento";

if (contractUUID.original_key_map == undefined) {
  contractUUID.original_key_map = contractUUID1;
}


        this.loading = true;
        var params = {
          contractId: this.contractUUID, //id de contrato que queres updatear los fields
          index_card: contractUUID
        };
        
        
        // await this.saveNewValue();

        
        this.contractService.contractTableUpdate(params).then(
          async (result: any) => {
            await this.saveNewValue();
            // this.loading = false;
              
          },
          (error: any ) => {
            console.log(error);
            this.loading = false;
            Swal.fire(
              'Error al actualizar datos',
              '',
              'error'
            )
          }
        )
      }
    })
  }

}
function quitarAcentos(cadena){
	const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
	return cadena.split('').map( letra => acentos[letra] || letra).join('').toString();	
}
