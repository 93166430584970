import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { ContractService } from 'src/services/contract.service';
import { LocalDataSource } from 'ng2-smart-table';
@Component({
  selector: 'app-contracts-expirations',
  templateUrl: './contracts-expirations.component.html',
  styleUrls: ['./contracts-expirations.component.scss']
})
export class ContractsExpirationsComponent implements OnInit {
   list=[];
   data=[];
   dataSource =new LocalDataSource();
  constructor(private contractService: ContractService) { }

  async ngOnInit() {
     this.contractService.contractStatusGetAll().then(
      (result: any) => {
        const userDB=result.records[0][0];
        this.table(userDB);
        
      }
    );
    
  }
  async table(obj){
    
       await obj.forEach(async(element) => {
           this.contractService.contractFinish(element.contractUUID).then(
            (result: any) => {
              var userDB = result.index_card
              if (userDB.vencimientoplazodelarrendamiento != null) {
                
                
                var currentDate = new Date();
                let currentDate1 = currentDate.getFullYear() + "-" + (currentDate.getMonth()+1) + "-" + currentDate.getDate();
                console.log(currentDate1);
                // const formatDate = (currentDate)=>{
                //   let formatted_date = currentDate.getDate() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getFullYear()
                //    return formatted_date;
                //   }
                  
                var fechaInicio = new Date(currentDate1).getTime();
                var fechaFin    = new Date(userDB.vencimientoplazodelarrendamiento).getTime();

                var diff = fechaFin - fechaInicio;
                var noDay = parseInt((diff/(1000*60*60*24)).toFixed(0));
                if (noDay<= element.contractDaysEnd) {
                  if (noDay>=0) {
                    this.list.push(result.index_card);
                 this.data.push({
                  id: userDB.vencimientoplazodelarrendamiento,
                  name: element.contractName,
                  contact: noDay+"",
                  email: userDB.clientearrendataria
                })
                this.dataSource = new LocalDataSource(this.data);
                  }else{
                    this.list.push(result.index_card);
                 this.data.push({
                  id: userDB.vencimientoplazodelarrendamiento,
                  name: element.contractName,
                  contact: "Vencido",
                  email: userDB.clientearrendataria
                })
                this.dataSource = new LocalDataSource(this.data);
                  }
                }
                
                 
              }
              
            }
          );
        })
      

  }

  getTable(params){
    this.contractService.contractFinish(params).then(
      (result: any) => {
        this.list.push(result.index_card);
        return result.index_card;
      }
    );
  }

  settings = {
    hideSubHeader: true,
    mode: "external",
    actions: {
      columnTitle: "",
      edit: false,
      delete: false
    },
    edit: {
      editButtonContent: "<i class='nb-edit'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='nb-trash'></i>"
    },
    columns: {
      name: {
        title: 'Nombre del Contrato'
      },
      email: {
        title: 'Cliente Arrendatario'
      },
      id: {
        title: 'Fecha de vencimiento'
      },
      contact: {
        title: 'Número de Días para su vencimiento'
      },
    },
    attr: {
      class: "table table-bordered"
    },
  };

  // data = [
  //   {
  //     id: "06/06/2021",
  //     name: "A-25478Z",
  //     contact: "Bret@bret.net",
  //     email: "A25"
  //   },
  //   {
  //     id: "01/06/2021",
  //     name: "5448941-F",
  //     contact: "Rey.Padberg@rosamond.biz",
  //     email: "D15"
  //   }
  // ];

}
