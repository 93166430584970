import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FolderService } from 'src/services/folder.service';
import Swal from 'sweetalert2';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit {
  loading=false;
  folders = [];
  constructor(
    private folderService: FolderService,
    private router: Router,
    private toastrService: NbToastrService,
  ) { }

  async ngOnInit() {
    await this.getFolders();
  }

  headTableNavigate(folder) {

    var folderId = folder.folderId;
    this.router.navigate(['/foldersView/' + folderId]);
  }

  getFolders(){
    this.folderService.folderGetAll().then(result=>{
      console.log(result);

      console.log(result)
      this.folders = result.records[0][0];
      
    })
  }

  saveNewFolder(){
    
    Swal
    .fire({
        title: "Ingrese el Nombre de la carpeta",
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Crear",
        confirmButtonColor: '#004F9F',
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#898989",
        
    })
    .then(resultado => {
        if (resultado.value) {
            const value= resultado.value;
            let folder = {folderName:value+"",folderDescription:""}
            this.folderService.newFolder(folder).then(response=>{
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'success';
              this.toastrService.show(status || '', 'Carpeta creada correctamente', { position, status });
              
          setTimeout(function(){
            window.location.reload();
            },300)
    })
        }
    });

    
  }

  deleteFolder(folder) {
    Swal.fire({
      title: '¿Está seguro de eliminar esta carpeta?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
       

        this.folderService.folderDelete(folder.folderId).then(result=>{
          console.log(result);
        if (result.statusCode == 200) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'success';
              this.toastrService.show(status || '', 'Eliminado correctamente', { position, status });
              
          setTimeout(function(){
            window.location.reload();
            },300)
        }
          
        })
       
      }
    })
  }

}
