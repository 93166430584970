<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<nb-card class="card">
  <nb-card-header>
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 inputs">
        <h1> <img src="../../../../assets/images/contract.png" style="width: 3rem !important;">  Contratos</h1>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center !important;">
        <h1> <img src="../../../../assets/images/cloud-computing.png" style="width: 3rem !important;"> Carga de contratos</h1>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <form novalidate [formGroup]="formImport" style="padding-top: 1.2rem !important;">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input nbInput type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" accept="application/pdf" (change)="onFileChange($event.target.files)">
              <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i>Cargar contrato</label>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6" style="align-self: center !important;">
        <button nbButton (click)="preUpload()">Subir</button>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center !important;">
        <h1> <img src="../../../../assets/images/sand-clock.png" style="width: 3rem !important;"> Estado de contratos</h1>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12">
        <ng2-smart-table [settings]="settings" [source]="contractStatusList" (edit)="onUserRowSelect($event)"></ng2-smart-table>
      </div>
    </div>
  </nb-card-body>
</nb-card>
