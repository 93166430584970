import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { UserModel } from 'src/app/models/userModel';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-user-auto-confirm',
  templateUrl: './user-auto-confirm.component.html',
  styleUrls: ['./user-auto-confirm.component.scss']
})
export class UserAutoConfirmComponent implements OnInit {
  loading = false;
  userName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit(){
    this.route.paramMap.subscribe((params: any) => {
      const userEmail = params.params.userEmail;
      const verificationCode = params.params.verificationCode;
      if (userEmail !== undefined || verificationCode !== undefined) {
        this.userAutoValidation(userEmail, verificationCode);
      }
    });
  }

  userAutoValidation(userEmail, verificationCode) {
    this.loading = true;
    const usrName = userEmail;
    let userId = '';
    let password = '';
    this.authService.confirmUser(userEmail, verificationCode).then(
      (result) => {
        this.authService.getUsers().then(
          (resultUsers: any) => {
            const listUsers = resultUsers.Users;
            listUsers.forEach(user => {
              const sub = user.Attributes.find((x) => x.Name === 'sub');
              const email = user.Attributes.find((x) => x.Name === 'email');
              if (userEmail === email.Value) {
                userId = sub.Value;
              }
            });
            this.userService.oneUserBackend(usrName).then(
              (resultUsers: any) => {
                if (typeof (resultUsers) === 'string') {
                  resultUsers = JSON.parse(resultUsers);
                } else {
                  throw resultUsers.errorMessage;
                }
                const userDB: UserModel = resultUsers.records[1][0];
                password = userDB.userPassword;
                // this.sesService.sendEmail(usrName, password, usrName, subdomain);
                let params = {
                  userName: usrName,
                  userPassword: password,
                  userEmail: usrName,
                  URLSite: environment.devsite,
                }
                this.authService.sendEmail(params);
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show('', 'Usuario validado', { position, status });
                this.loading = false;
                this.router.navigate(['/Login']);
              }
            ).catch((err) => {
              console.error(err);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err,
                { position, status });
              this.loading = false;
              this.router.navigate(['/Login']);
            });
          }
        ).catch((err) => {
          console.error(err);
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err,
            { position, status });
          this.loading = false;
          this.router.navigate(['/Login']);
        });

      }
    ).catch(
      (err) => {
        console.error(err);
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          'Usuario o código incorrecto.',
          { position, status });
        this.loading = false;
        this.router.navigate(['/Login']);
      }
    );
  }

}
