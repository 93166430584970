import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyARecord } from 'dns';
import { element } from 'protractor';
import { ContractStatusModel } from 'src/app/models/contractStatusModel';
import { FolderService } from 'src/services/folder.service';
import Swal from 'sweetalert2';
import contractStatus from '../../../../assets/tables/contractStatus.json';
import filteredContracts from '../../../../assets/tables/folderTable.json';

@Component({
  selector: 'app-folder-view',
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss']
})
export class FolderViewComponent implements OnInit {
  contract = {} as ContractStatusModel;
  settings = filteredContracts;
  settingsf = filteredContracts;
  contractStatusList = new Array();
  filteredContractList = new Array();
  folderName = "";
  contractsAvailable = [];
  folder = {} as any;
  loading = false;
  btnAdd = true;
  constructor(
    private folderService: FolderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe((params: any) => {
      let folderId = params.params.folderId;
      this.getFolders(params.params.folderId);
      this.folderService.folderGetContracts(folderId).then(response => {
        if (typeof (response) === 'string') {
          response = JSON.parse(response);
        } else {
          throw response.errorMessage;
        }
        console.log(response);
        this.contractStatusList = response.records;
        this.loading = false;

      })
    });
  }
  getFolders(id) {
    this.folderService.folderGetAll().then(result => {
      console.log(result);

      console.log(result)
      let folders = result.records[0][0];
      let folderFind = folders.find(x => x.folderId == id);
      if (folderFind != undefined && folderFind != null) {
        this.folder = folderFind;
        this.folderName = this.folder.folderName;
        console.log(this.folder);
      }

    })
  }
  onCustomAction(event) {
    console.log(event);
    if (event.action == "edit") {
      this.onUserRowSelect(event);
    }
    if (event.action == "delete") {
      this.headTableNavigate(event);
    }
    if (event.action == "ourCustomAction") {
      this.remove(event);
    }
  }

  cancel() {
    this.btnAdd = true;
  }
  closed(){
    this.router.navigate(['/folders']);
  }
  addContracts() {
    this.loading = true;
    this.btnAdd = false;
    this.folderService.folderGetContracts(0).then(response => {
      if (typeof (response) === 'string') {
        response = JSON.parse(response);
      } else {
        throw response.errorMessage;
      }

      if (response.records.length <= 0) {
        this.loading = false;
        Swal.fire(
          'No hay contratos disponibles',
          '',
          'warning'
        )
        this.btnAdd = true;
      } else {
        response.records.forEach(element => {
          element.add = false;
        });
        this.contractsAvailable = response.records;
        this.loading = false;
      }




    })

  }

  async remove(contractUUID) {
    this.loading = true;
    var contract = contractUUID.data.contractUUID;

    await this.folderService.folderAddContract(0, contract).then(response => {

      this.loading = false;
      Swal.fire({
        title: 'Contrados Eliminado de esta carpeta exitosamente',
        text: "Presione OK para continuar",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })

      console.log(response)
    });

  }

  addContractsNew() {
    this.loading = true;
    this.contractsAvailable = this.contractsAvailable.filter(x => x.add == true);
    console.log(this.contractsAvailable);
    let contador = 0;
    let size = this.contractsAvailable.length;
    this.contractsAvailable.forEach(async (element) => {
      await this.folderService.folderAddContract(this.folder.folderId, element.contractUUID).then(response => {
        contador++;
        if (contador >= size) {
          this.loading = false;
          Swal.fire({
            title: 'Contrados Agregados exitosamente',
            text: "Presione OK para continuar",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        }
        console.log(response)
      });
    });
  }
  onUserRowSelect(event) {
    const UUID = event.data.contractPDFS3;
    window.open(UUID, '_blank');
  }
  headTableNavigate(contractUUID) {

    var contract = contractUUID.data.contractUUID;
    this.router.navigate(['/headContractTable/' + contract]);
  }
}
